import { Outlet, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Sidebar from './Sidebar';
import { getUserDetails } from '../services/userDetails/user.services'; 
import { DDSLoader } from 'den-design-system';

const LayoutWithSidebar = () => {
  const params = useParams();
  const [loading,setLoading] = useState<boolean>(false);
  useEffect(() => {
    const token = localStorage.getItem('idToken');
    const fetchUserProfile = async () => {
      try {
        if (token) {
          const result = await getUserDetails(token);

          const { status } = result;

          if (status === 401) {
            window.location.href = '/home';
          }
        } else {
          window.location.href = '/home';
        }
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        window.location.href = '/home';
      }
    };

    fetchUserProfile();
  }, []);
  return (
    <>
    {loading ? (<DDSLoader loading={true} />)
    :
    <>
    <div className='layout'>
      <Sidebar loading={loading} setLoading={setLoading} params={params} />
      <div className='content'>
        <Outlet />
      </div>
    </div>
    </>
    
  }
  </>
  );
};

export default LayoutWithSidebar;
