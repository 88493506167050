import axios from 'axios';
export const settingsService = (
  workspaceId: string,
  token: string
): Promise<{ workspaceStatus: number; workspaceData?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/settingsDetail`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        data: {
          workspace_id: workspaceId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve({
            workspaceStatus: response.status,
            workspaceData: response.data.member_details,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};

interface Video {
  id: string;
  video_url: string;
}

interface VideoGroup {
  video_id: string;
  group_id: string;
  video: Video;
}

type VideoResponseItem = [VideoGroup[], string, number];

interface VideoDataResponse {
  videos: VideoResponseItem[];
}

interface ApiResponse<T> {
  status: number;
  data: T;
}

export const getWorkspaceVideo = (
  token: string,
  workspaceId: string
): Promise<ApiResponse<VideoDataResponse>> => {
  console.log('token', token);
  console.log('workspaceId', workspaceId);

  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/comment/videos`,
        {
          params: { workspace_id: workspaceId },
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data as VideoDataResponse,
          });
        } else {
          reject({ status: response.status, message: 'Request failed' });
        }
      })
      .catch((error) => {
        console.error('Error fetching video details', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
