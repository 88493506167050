import axios from 'axios';

export const deletemember = (
  token: string,
  user_id: string,
  workspace_id: string
): Promise<{ status: number; data?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/deletemember`;

  return new Promise((resolve, reject) => {
    axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
        },
        data: { user_id, workspace_id },
      })
      .then((response) => {
        console.log(workspace_id, 'workspace_idworkspace_id');
        console.log(response, 'reponse ');
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
