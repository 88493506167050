import axios from 'axios';

export const getlink = (
  token: string,
  email: string,
  workspace_id: string
): Promise<{ status: number; data?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/invitemember`;

  return new Promise((resolve, reject) => {
    // Suggestion: Avoid sending the token in both the request body and headers if not required. [security]
    // axios.post(
    //   url,
    //   { email, workspace_id },
    //   { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'ngrok-skip-browser-warning': 'true' } }
    // )
    axios
      .post(
        url,
        { token, email, workspace_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'true',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
