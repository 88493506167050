import 'den-design-system/style.css';
import '../index.css';

import { DDSMenu, DDSMenuList, DDSTypography } from 'den-design-system';

import { ReactComponent as Divider } from '../assets/workspaceDropdownDivider.svg';
import { ReactComponent as LogoutIcon } from '../assets/logoutIcon.svg';
import { ReactComponent as ProfileMenu } from '../assets/menuProfile.svg';
import React from 'react';
import { ReactComponent as SettingsIcon } from '../assets/settingsIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';

interface ProfileCardProps {
  name: string;
  email: string;
  image: string | React.ReactNode;
  id: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  email,
  image,
  id,
}) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const pathname = location.pathname;
  console.log('pathnnaem',pathname);

  return (
    <div className='dds-flex'>
      <div className='profile-card-image'>
        {typeof image === 'string' ? (
          <img
            src={
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
            }
            className='profile-card-profile-avatar'
          />
        ) : (
          image
        )}
        <div className='profile-card'>
          <div className='profile-card-info'>
            <DDSTypography.Paragraph size='para' className='profile-card-name'>
              {name}
            </DDSTypography.Paragraph>
            <DDSTypography.Paragraph size='para' className='profile-card-email'>
              {email}
            </DDSTypography.Paragraph>
          </div>
          {open && (
            <div
              className='profile-card-menu'
              style={{
                position: 'absolute',
                marginLeft: '112px',
                marginBottom: '120px',
              }}
            >
              <DDSMenu
                open={open}
                style={{
                  textAlign: 'left',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <DDSMenuList>
                  <div
                    className='profile-menu-item'
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '4px',
                      padding: '0px',
                    }}
                  >
                    <SettingsIcon />
                    <button
                      onClick={() => {
                        setOpen(false);
                        navigate(`/personal_settings/${id}`);
                      }}
                    >
                      <DDSTypography.Paragraph
                        size='para'
                        className='profile-menu-item-settings-text'
                        style={{
                          color: '#79310E',
                        }}
                      >
                        Settings
                      </DDSTypography.Paragraph>
                    </button>
                  </div>
                </DDSMenuList>
                <Divider />
                <DDSMenuList>
                  <button
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '4px',
                        padding: '0px',
                      }}
                    >
                      <LogoutIcon />
                      <DDSTypography.Paragraph
                        size='para'
                        className='profile-menu-item-logout-text'
                        style={{ color: '#79310E' }}
                      >
                        Log out
                      </DDSTypography.Paragraph>
                    </div>
                  </button>
                </DDSMenuList>
              </DDSMenu>
            </div>
          )}
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <ProfileMenu onClick={toggleMenu} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileCard;
