import 'den-design-system/style.css';
import '../index.css';

import {
  DDSButton,
  DDSChips,
  DDSModal,
  DDSTypography,
} from 'den-design-system';
import { useEffect, useState } from 'react';

import { ReactComponent as BulletIcon } from '../assets/bulletTick.svg';
import { ReactComponent as InviteCrossIcon } from '../assets/inviteMemberCancel.svg';
import { Plans } from '../services/personalSettingDetails/personalplans.services';
import MembersInvite from './MembersInviteInput';

interface MembersProps {
  id: string;
  name: string;
  role: string;
  status?: string;
  image?: string;
}

interface MemberInput {
  name: string;
  email: string;
}

const WorkspacePlans = () => {
  interface PlanDetails {
    id: number;
    name: string;
    memberLimit: number;
    videoLimit: number;
    rate: string;
    description: string;
    timeLimit: string;
  }

  const addMembers = (membersInput: MemberInput[]) => {
    const newMembers = membersInput.map((memberInput, index) => ({
      id: (members.length + index + 1).toString(),
      name: memberInput.name,
      email: memberInput.email,
      image:
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png', // Replace with actual image path
      role: 'member',
      status: 'Remove',
    }));
    setMembers((prevMembers) => [...prevMembers, ...newMembers]);
  };

  const removeMember = (id: string) => {
    setMembers(members.filter((member) => member.id !== id));
  };

  const [freeplan, setFreePlan] = useState<PlanDetails>({
    id: 0,
    name: '',
    memberLimit: 0,
    videoLimit: 0,
    rate: '',
    description: '',
    timeLimit: '',
  });

  const [pro, setProPlan] = useState<PlanDetails>({
    id: 0,
    name: '',
    memberLimit: 0,
    videoLimit: 0,
    rate: '',
    description: '',
    timeLimit: '',
  });

  // const [planType, setPlanType] = useState(1);
  // const { id } = useParams<{ id: string }>();

  const [modalOpen, setModalOpen] = useState(false);
  const [members, setMembers] = useState<MembersProps[]>([]);

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    const handleFetchData = async () => {
      if (token) {
        try {
          let plandata = await Plans(token, 3);
          console.log(plandata.plandata[0], 'plandata');
          setFreePlan({
            id: plandata.plandata[0]['id'],
            name: plandata.plandata[0]['name'],
            memberLimit: plandata.plandata[0]['memberlimit'],
            videoLimit: plandata.plandata[0]['videolimit'],
            rate: plandata.plandata[0]['rate'],
            description: plandata.plandata[0]['details'],
            timeLimit: plandata.plandata[0]['timelimit'],
          });

          plandata = await Plans(token, 4);
          console.log(plandata.plandata[0], 'plandata');
          setProPlan({
            id: plandata.plandata[0]['id'],
            name: plandata.plandata[0]['name'],
            memberLimit: plandata.plandata[0]['memberlimit'],
            videoLimit: plandata.plandata[0]['videolimit'],
            rate: plandata.plandata[0]['rate'],
            description: plandata.plandata[0]['details'],
            timeLimit: plandata.plandata[0]['timelimit'],
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    handleFetchData();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      <div
        style={{
          border: '1px solid #F89608',
          borderRadius: '20px',
          height: '406px',
          padding: '20px 20px 105px 20px',
          width: '250px',
          textAlign: 'left',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '7px',
          }}
        >
          <DDSTypography.Title
            type='h4'
            color='#79310E'
            className='plan-heading'
          >
            {freeplan.name}
          </DDSTypography.Title>

          <DDSTypography.Paragraph size='para' className='plan-description'>
            Get started with personal workspace
          </DDSTypography.Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <DDSTypography.Title
            type='h2'
            color='black'
            className='plan-cost'
            style={{
              padding: '50px 0px 50px 0px',
            }}
          >
            {freeplan.rate}
          </DDSTypography.Title>
          <DDSTypography.Paragraph
            size='para'
            color='black'
            className='cost-description-workspace'
          >
            /member/month
          </DDSTypography.Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <DDSTypography.Paragraph size='para' className='plan-features-title'>
            Key features
          </DDSTypography.Paragraph>

          <div className='plan-features-list'>
            <div className='plan-features-list-option'>
              <BulletIcon />
              <DDSTypography.Paragraph size='caption'>
                a personal workspace
              </DDSTypography.Paragraph>
            </div>
            <div className='plan-features-list-option'>
              <BulletIcon />
              <DDSTypography.Paragraph size='caption'>
                Add comments up to {freeplan.videoLimit} streams
              </DDSTypography.Paragraph>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          border: '1px solid #F89608',
          borderRadius: '20px',
          padding: '20px',
          width: '250px',
          height: '406px',
          textAlign: 'left',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '7px',
          }}
        >
          <DDSTypography.Title
            type='h4'
            color='#79310E'
            className='plan-heading'
          >
            {pro.name}
          </DDSTypography.Title>

          <DDSTypography.Paragraph size='para' className='plan-description'>
            Get started with personal workspace
          </DDSTypography.Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <DDSTypography.Title
            type='h2'
            color='black'
            className='plan-cost'
            style={{
              padding: '50px 0px 50px 0px',
            }}
          >
            {pro.rate}
          </DDSTypography.Title>
          <DDSTypography.Paragraph
            size='para'
            color='black'
            className='cost-description-workspace'
          >
            /member/seat
          </DDSTypography.Paragraph>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <DDSTypography.Paragraph size='para' className='plan-features-title'>
            Key features
          </DDSTypography.Paragraph>

          <div className='plan-features-list'>
            <div className='plan-features-list-option'>
              <BulletIcon />
              <DDSTypography.Paragraph size='caption'>
                a personal workspace
              </DDSTypography.Paragraph>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '25px',
              }}
            >
              <div className='plan-features-list-option'>
                <BulletIcon />
                <DDSTypography.Paragraph size='caption'>
                  Add comments up to {pro.videoLimit} streams
                </DDSTypography.Paragraph>
              </div>
              <DDSButton
                onClick={() => {
                  setModalOpen(true);
                }}
                label='Buy Add-ons'
                className='upgrade-button upgrade-button-text'
              />
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <DDSModal
          open={modalOpen}
          setOpen={setModalOpen}
          onIconClose={() => setModalOpen(false)}
          id='add-ons-modal'
          className='add-ons-padding'
          defaultFooter={false}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <DDSTypography.Title type='h5'>
              Invite Members to your Workspace
            </DDSTypography.Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <div
                style={{
                  gap: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                  }}
                >
                  <MembersInvite onSendInvite={addMembers} />
                  <div style={{ overflowY: 'auto', maxHeight: '40vh' }}>
                    {members.map((member) => (
                      <div
                        key={member.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '20px',
                          paddingBottom: '10px',
                          borderBottom: '1px solid #eaeaea',
                        }}
                      >
                        {/* <MembersInvite onSendInvite={generateInvitation} /> */}
                        <img
                          src={member.image}
                          alt={`${member.name}'s avatar`}
                          style={{
                            borderRadius: '50%',
                            width: '40px',
                            height: '40px',
                            marginRight: '15px',
                          }}
                        />
                        <div style={{ flexGrow: 1 }}>
                          <DDSTypography.Title
                            type='h5'
                            className='new-member-invite-name'
                          >
                            {member.name}
                          </DDSTypography.Title>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <DDSChips
                            color='#79310E'
                            style={{
                              backgroundColor: '#FEF5E7',
                              color: '#79310E',
                              padding: '5px 10px',
                              borderRadius: '5px',
                            }}
                            className='invited-chip-text invited-member'
                            label={
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '15px',
                                }}
                              >
                                <DDSTypography.Paragraph
                                  size='caption'
                                  className='invited-chip-text'
                                >
                                  {member.status}
                                </DDSTypography.Paragraph>
                                <button
                                  onClick={() => removeMember(member.id)}
                                  style={{
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: '#79310E',
                                  }}
                                >
                                  <InviteCrossIcon />
                                </button>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    width: '280px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    borderRadius: ' 6px',
                    padding: '15px',
                    border: '1px solid  #FFF1C6',
                    background: ' #FFFAEB',
                  }}
                >
                  <div
                    style={{
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <DDSTypography.Title
                      type='h5'
                      className='add-ons-summary-title'
                    >
                      Summary
                    </DDSTypography.Title>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <DDSTypography.Title
                        type='h4'
                        className='add-ons-cost-per-seat'
                      >
                        $7
                      </DDSTypography.Title>
                      <DDSTypography.Paragraph
                        size='caption'
                        className='add-ons-cost-per-seat-description'
                      >
                        per Seat
                      </DDSTypography.Paragraph>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '15px',
                      gap: '30px',
                      
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <DDSTypography.Title
                        type='h4'
                        className='add-ons-members-count'
                      >
                        {members.length} Members
                      </DDSTypography.Title>
                      <DDSTypography.Title
                        type='h4'
                        className='add-ons-members-count'
                      >
                        ${members.length * 7}.00
                      </DDSTypography.Title>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <DDSTypography.Title
                        type='h4'
                        className='add-ons-total-cost'
                      >
                        Total Due
                      </DDSTypography.Title>
                      <DDSTypography.Title
                        type='h4'
                        className='add-ons-total-cost'
                      >
                        ${members.length * 7}.00
                      </DDSTypography.Title>
                    </div>
                  </div>
                </div>
              </div>
              <DDSButton label='Add Member' />
            </div>
          </div>
        </DDSModal>
      )}
    </div>
  );
};

export default WorkspacePlans;
