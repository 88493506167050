import axios from 'axios';
export const updateNotificationPreference = (
  token: string,
  notificationpreference: boolean
): Promise<{ status: number; data?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/notificationpreference`;

  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        { notificationpreference },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true',
          },
        }
      )

      .then((response) => {
        console.log(
          notificationpreference,
          'notificationpreferencenotificationpreference'
        );
        console.log(response, 'notification');
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
