import axios from 'axios';
export const getPersonalWorkspaceId = (
  token: string
): Promise<{ workspaceStatus: number; personalWorkspace?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/user_workspace`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response);
          console.log(response.data, 'response.data');

          const workspaces = response.data.member_details.map(
            (member: any) => ({
              role: member.role,
              workspaceData: [
                {
                  name: member.workspace.name,
                  id: member.workspace.id,
                  planType: member.workspace.planType,
                },
              ],
            })
          );
          console.log('workspaces', workspaces);

          const personalPlanIds = workspaces
            .filter(
              (workspace: any) =>
                workspace.workspaceData[0].planType === 1 ||
                workspace.workspaceData[0].planType === 2
            )
            .map((workspace: any) => workspace.workspaceData[0].id);
          console.log('personal plans', personalPlanIds[0]);

          if (personalPlanIds.length > 0) {
            console.log('inside ;;;');

            console.log(encodeURIComponent(personalPlanIds[0]));

            resolve({
              workspaceStatus: response.status,
              personalWorkspace: personalPlanIds[0],
            });
          } else {
            reject({ status: response.status });
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
