import axios from 'axios';

interface User {
  name: string;
}

interface MemberProps {
  data: any;
}

export const getMemberDetails = (
  token: string,
  workspaceId: string
): Promise<MemberProps> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/workspace_members`;
    axios
      .get(url, {
        params: {
          workspace_id: workspaceId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve({
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
