import {
  DesignSystemEnum,
  ThemeContext,
  ThemeContextType,
} from 'den-design-system';
import { useContext, useEffect } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

//import DowngradedPlan from './Components/DowngradedPlan';
import LandingPage from './Components/LandingPage';
import LayoutWithSidebar from './Components/LayoutWithSidebar';
import LoginSuccess from './Components/LoginSuccess';
import Settings from './Components/Settings';
import SignIn from './Components/SignIn';
import "./App.css";
import WorkSpace from './Components/WorkSpace';

// Define your routes
const App = () => {
  const { saveTheme } = useContext(ThemeContext) as ThemeContextType;
  useEffect(() => {
    saveTheme({
      designSystem: DesignSystemEnum.MUI,
      color: {
        primary: '#FAEDCB',
        component: {
          Radio: {
            backgroundColor: '#F89608',
            hoverColor: '#F89608',
          },
          Table: {
            rowHoverBg: '#FFFAEB',
          },
        },
      },
    });
  }, []);

  return (
    <Router>
      <Routes>
      <Route path='/' element={<LandingPage />} /> {/* Changed this line */}
      <Route path='/login_success' element={<LoginSuccess />} />
        <Route path='*' element={<Navigate to='/home' />} />
        <Route path='/home' element={<LandingPage />} />
        <Route
          path='/home/:workspace_id/:email/:invitation_code'
          element={<LandingPage />}
        />
        {/* Routes with Sidebar */}
        <Route element={<LayoutWithSidebar />}>
        
        
          <Route path='/dashboard/:id' element={<WorkSpace />} />
          <Route
            path='/personal_settings/:id'
            element={<Settings isPersonal={true} />}
          />
          <Route
            path='/joined-workspace/settings/:id'
            element={<Settings isPersonal={false} />}
          />
          <Route
            path='/my-workspace/settings/:id'
            element={<Settings isPersonal={false} />}
          />
        </Route>
      </Routes>
    </Router>
    // <DowngradedPlan />
    // <MembersTab />
    // <LimitExceeded />
    // <BillingHistory />
    // <WorkspacePlans />
  );
};

// Render your application
export default App;
