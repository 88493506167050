import 'den-design-system/style.css';

import {
  DDSButton,
  DDSChips,
  DDSTable,
  DDSTypography,
} from 'den-design-system';
import React, { useState } from 'react';

import { ReactComponent as DownloadIcon } from '../assets/downloadIcon.svg';
import { ReactComponent as SuccessChip } from '../assets/successChip.svg';

enum PaymentStatus {
  Success = 'success',
  Failed = 'failed',
  Pending = 'pending',
}

interface PaymentProps {
  date: Date;
  status: PaymentStatus;
  planName: string;
}

const columns = [
  {
    dataLabel: 'planName',
    heading: 'Plan Name',
    render: (planName: any) => planName,
  },
  {
    dataLabel: 'status',
    heading: 'Status',
    render: (status: any) => {
      let color = '';
      let backgroundColor = '';
      switch (status) {
        case PaymentStatus.Success:
          color = '#14BA6D';
          backgroundColor = '#E3F3E9';
          break;
        case PaymentStatus.Failed:
          color = 'red';
          backgroundColor = 'red';
          break;
        case PaymentStatus.Pending:
          color = 'orange';
          break;
        default:
          color = 'black';
      }
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
          }}
        >
          <DDSChips
            startIcon={<SuccessChip />}
            className='status-chip'
            color={color}
            shape='rounded'
            size='small'
            style={{
              backgroundColor: backgroundColor,
            }}
            label={status}
          />
        </div>
      );
    },
  },
  {
    dataLabel: 'date',
    heading: 'Date',
    render: (date: any) => {
      const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }).format(new Date(date));

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '35px',
          }}
        >
          <span>{formattedDate}</span>
          <DownloadIcon
            style={{
              cursor: 'pointer',
            }}
          />
        </div>
      );
    },
  },
];

const BillingHistory = () => {
  const [billingHistory, setBillingHistory] = useState<PaymentProps[]>([]);

  const addBillingRow = (newPayment: PaymentProps) => {
    setBillingHistory((prevHistory) => [...prevHistory, newPayment]);
  };

  const handlePaymentSuccess = (paymentData: PaymentProps) => {
    addBillingRow(paymentData);
  };

  const handleStripe = () => {
    // Simulate payment success
    handlePaymentSuccess({
      date: new Date(),
      status: PaymentStatus.Success,
      planName: 'Pro Plan',
    });
  };

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <DDSTypography.Title type='h5'>Billing History</DDSTypography.Title>
      <div>
        <DDSButton onClick={handleStripe} label='Handle'></DDSButton>
        <DDSTable
          id='billing-history-table'
          data={billingHistory}
          columns={columns}
          selectable={false}
          loading={false}
        />
      </div>
    </div>
  );
};

export default BillingHistory;
