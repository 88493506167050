import axios from 'axios';

interface UserData {
  id: string;
  name: string;
  email: string;
}

interface UserExistsProps {
  status: number;
  exists: boolean;
  data: UserData[];
}

export const checkIfUserExists = (email: string): Promise<UserExistsProps> => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/isuser`;
    axios
      .get(url, {
        params: { email: email },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          'ngrok-skip-browser-warning': 'true',
        },
      })
      .then((response) => {
        if (response.data.data) {
          console.log('inside success');

          resolve({
            status: 200,
            exists: true,
            data: response.data.data,
          });
        } else {
          console.log('inside not member');

          resolve({
            status: 200,
            exists: false,
            data: [],
          });
        }
      })
      .catch((error) => {
        console.log('inside error');

        console.error('Error fetching user details:', error);
        reject({
          status: error.response?.status || 500,
          exists: false,
          data: [],
        });
      });
  });
};
