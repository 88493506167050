import 'den-design-system/style.css';
import '../index.css';

import { DDSSelectV2, DDSTypography } from 'den-design-system';

import PersonalPlans from './PersonalPlans';
import React from 'react';
import WorkspacePlans from './WorkspacePlans';
const options = [
  {
    value: 'personal',
    label: 'Personal plans',
  },
  {
    value: 'workspace',
    label: 'Workspace plans',
  },
];
interface OrganizationProps {
  isOrganization: boolean;
}
const Plans: React.FC<OrganizationProps> = ({ isOrganization }) => {
  // Set the default selected option based on whether it's an organization or not
  const [selectedOption, setSelectedOption] = React.useState(
    isOrganization ? 'workspace' : 'personal'
  );
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '50px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <DDSTypography.Title type='h3' className='all-plans-title'>
          All Plans
        </DDSTypography.Title>
        <div className='override-select-border override-select'>
          {!isOrganization && (
            <DDSSelectV2
              className='dds-select-component rc-select rc-select-item-option-content'
              defaultValue='personal'
              options={options}
              name='Label'
              searchable={false}
              // onSelect={(value) => handleSelectChange(value)}
              placeholder='Select an option'
              menuStyle={{
                alignItems: 'center !important',
                gap: '10px',
                borderRadius: '6px !important',
                border: '1px solid #FEBC2E !important',
                backgroundColor: '#fff !important',
              }}
              menuListStyle={{
                color: '#79310E !important',
                fontFamily: 'Poppins ',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '22px',
              }}
            />
          )}
        </div>
      </div>
      {!isOrganization && selectedOption === 'personal' && <PersonalPlans />}
      {!isOrganization && selectedOption === 'workspace' && <WorkspacePlans />}
      {isOrganization && <WorkspacePlans />}
    </div>
  );
};
export default Plans;
