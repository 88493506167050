import '../index.css';

import {
  DDSButton,
  DDSModal,
  DDSTooltip,
  DDSTypography,
} from 'den-design-system';
import React, { useEffect, useState } from 'react';

import { ReactComponent as Divider } from '../assets/divider.svg';
import { ReactComponent as TooltipIcon } from '../assets/tooltip-plan.svg';
import { getBillings } from '../services/personalSettingDetails/billing.services';
import { getPlanTypeById } from '../utils/getPlanTypeById';
import { updatePlantype } from '../services/workspace/planType.services';
import { useParams } from 'react-router-dom';
import { user_workspace } from '../services/userDetails/userWorkspace.services';

const PlanAndBillingPersonal = () => {
  const [modalOpen, setOpen] = React.useState<boolean>(false);

  interface BillingProp {
    name: string;
    rate: string;
    videolimit: number;
  }

  const [details, setDetails] = useState<BillingProp>({
    name: '',
    rate: '',
    videolimit: 0,
  });
  const [planType, setPlanType] = useState(1);
  const [workspaceid, setPworkspaceid] = useState('');
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const token = localStorage.getItem('idToken');
    const handleFetchData = async () => {
      if (token) {
        try {
          const result1 = await user_workspace(token);
          const { workspaceStatus, workspaceData } = result1;

          if (workspaceStatus === 200) {
            const billingWorkspace = await getBillings(token, id || '');
            const { billingstatus, billingdata } = billingWorkspace;
            console.log('billingdata', billingdata);

            setDetails({
              name: billingdata[0].workspace.PlanType['name'],
              rate: billingdata[0].workspace.PlanType['rate'],
              videolimit: billingdata[0].workspace.PlanType['videolimit'],
            });

            if (id) {
              const planType = await getPlanTypeById(id);
              console.log('planType', planType);
              setPlanType(planType || 1);
            }

            setPworkspaceid(id || '');
            console.log(`Workspace ID: ${id || ''}, Plan Type: ${planType}`);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    handleFetchData();
  }, []);

  const handleCancel = async () => {
    const token = localStorage.getItem('idToken');
    if (token) {
      try {
        await updatePlantype(token, workspaceid, 1);
        setOpen(false);
        window.location.reload();
      } catch (error) {
        console.error('Error updating plan type:', error);
      }
    }
  };

  const handleToggle = () => {
    setOpen(true);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '30px',
      }}
    >
      <div className='plan-container'>
        <div className='plan-header'>
          <DDSTypography.Paragraph
            color='black'
            size='caption'
            className='current-plan-text'
          >
            Current plan
          </DDSTypography.Paragraph>
          <DDSTypography.Paragraph
            color='black'
            size='caption'
            className='current-plan-scheme-text'
          >
            {details.name}
          </DDSTypography.Paragraph>
          <DDSTypography.Title type='h1' className='current-plan-cost-text'>
            {details.rate}
          </DDSTypography.Title>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DDSModal
              open={modalOpen}
              setOpen={setOpen}
              className='modal-content'
              defaultFooter={false}
              onIconClose={() => {
                setOpen(false);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <DDSTypography.Title type='h5' className='modal-title'>
                  Are you sure you want to cancel the Pro plan?
                </DDSTypography.Title>
                <DDSTypography.Paragraph size='caption' className='modal-note'>
                  Note:
                  <ul
                    style={{
                      listStyle: 'inside',
                    }}
                  >
                    <li>Canceling the plan the amount will not be refunded.</li>
                    <li>Access to certain streams will be locked.</li>
                  </ul>
                </DDSTypography.Paragraph>
                <DDSButton
                  label='Proceed'
                  className='modal-proceed-button modal-proceed-button-text'
                  onClick={handleCancel}
                />
              </div>
            </DDSModal>
            {planType === 2 && (
              <button onClick={handleToggle}>
                <DDSTypography.Title type='h5' className='cancel-plan-text'>
                  Cancel Plan
                </DDSTypography.Title>
              </button>
            )}
          </div>
        </div>

        <div className='plan-body'>
          <div className='plan-workspace-section'>
            <DDSTypography.Paragraph
              size='para'
              className='count-personal-workspace'
            >
              1
            </DDSTypography.Paragraph>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <DDSTypography.Paragraph
                size='para'
                className='personal-workspace-note'
              >
                Personal workspace
              </DDSTypography.Paragraph>
              <DDSTooltip
                id='tooltip-workspace'
                position='top'
                style={{
                  backgroundColor: '#411607 !important',
                }}
                className='tooltip-content tooltip-background'
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    className='tooltip-description'
                  >
                    Your personal workspace, where you can manage up to five
                    recordings, add your comments, and react with emojis—all
                    just for you.
                  </DDSTypography.Paragraph>
                }
              >
                <TooltipIcon />
              </DDSTooltip>
            </div>
          </div>
          <Divider className='plan-streams-divider' />
          <div className='plan-streams-section'>
            <DDSTypography.Paragraph size='para' className='stream-count'>
              {details.videolimit}
            </DDSTypography.Paragraph>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              <DDSTypography.Paragraph size='para' className='stream-note'>
                Streams
              </DDSTypography.Paragraph>
              <DDSTooltip
                position='top'
                id='tooltip-workspace'
                style={{
                  backgroundColor: '#411607 !important',
                }}
                className='tooltip-content'
                label={
                  <DDSTypography.Paragraph
                    size='para'
                    className='tooltip-description'
                  >
                    Manage up to 5 streams. To manage more, upgrade to the Pro
                    plan for unlimited videos
                  </DDSTypography.Paragraph>
                }
              >
                <TooltipIcon />
              </DDSTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanAndBillingPersonal;
