import axios from 'axios';
export const user_workspace = (
  token: string
): Promise<{ workspaceStatus: number; workspaceData?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/workspace-member/user`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response);
          console.log(response.data, 'response.data');

          resolve({
            workspaceStatus: response.status,
            workspaceData: response.data.member_details,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};

export const getWorkspaceVideo = (workspaceId: string) => {
  return new Promise((resolve, reject) => {
    // Suggestion: Switch GET request payload from 'data' to 'params' to correctly send query parameters. [possible bug]
    // axios.get(
    //   `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/comment/videos`,
    //   {
    //     params: { workspace_id: workspaceId },
    //     headers: { Authorization: `Bearer ${localStorage.getItem('idToken')}` },
    //   }
    // )
    axios
      .get(
        `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/comment/videos`,
        {
          data: {
            workspace_id: workspaceId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching video details', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
