import 'den-design-system/style.css';

import {
  DDSButton,
  DDSChips,
  DDSLoader,
  DDSModal,
  DDSTypography,
} from 'den-design-system';
import React, { useEffect, useState } from 'react';

import { ReactComponent as BinIcon } from '../assets/binIcon.svg';
import { ReactComponent as CopyLinkIcon } from '../assets/copyLinkIcon.svg';
import { ReactComponent as InviteCrossIcon } from '../assets/inviteMemberCancel.svg';
import { ReactComponent as InviteMemberDivider } from '../assets/inviteMemberDivider.svg';
import MembersInvite from './MembersInviteInput'; // Assuming it's in the same directory
import { ReactComponent as MembersLimitReached } from '../assets/memberLimitReachedLogo.svg';
import { deletemember } from '../services/userDetails/deletemember.services';
import { getMemberDetails } from '../services/workspace/members.services';
import { getlink } from '../services/invitation/link.services';
import { useParams } from 'react-router-dom';

// Interface for Member Props
interface MembersProps {
  id: string;
  name: string;
  email: string;
  role: string;
  status?: string;
  image?: string;
}

interface MemberDetail {
  role: string;
  user: {
    user_uuid: string;
    name: string;
    email: string;
  };
}

interface InviteProp {
  name: string;
  email: string;
}

interface WorkspaceDetail {
  isAdminWorkspace: boolean;
}

const MembersTab: React.FC<WorkspaceDetail> = ({ isAdminWorkspace }) => {
  const [members, setMembers] = useState<MembersProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [limitReachedModal, setLimitReachedModal] = useState<boolean>(false);

  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>(); // Ensure id is treated as a string

  useEffect(() => {
    if (members.length > 5) {
      setLimitReachedModal(true);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchMembers = async () => {
      const token = localStorage.getItem('idToken');
      if (id && token) {
        console.log('workspaceId', id);

        const data = await getMemberDetails(token, id);
        const memberDetails = data.data.member_details.map(
          (member: MemberDetail, index: number) => ({
            id: member.user.user_uuid,
            name: member.user.name,
            role: member.role as string,
            email: member.user.email,
            status: member.role === 'admin' ? 'Admin' : 'Invited',
            image:
              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png',
          })
        );
        console.log('memberDetails', memberDetails);
        setMembers(memberDetails);
      }
      setLoading(false);
    };
    fetchMembers();
  }, [id]);

  /*const addMembers = (names: string[]) => {
    const newMembers = names.map((name, index) => ({
      id: (members.length + index + 1).toString(),
      name,
      email: `${name.toLowerCase().replace(/ /g, '')}`, // Example email generation
      image:
        'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png', // Replace with actual image path
      role: 'member',
      status: 'Invited',
    }));
    // if (members.length + newMembers.length > 5) {
    //   setLimitReachedModal(true);
    //   return;
    // }
    setMembers((prevMembers) => [...prevMembers, ...newMembers]);
  };*/

  const handleCheckboxChange = (id: string) => {
    setCheckedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };

  const nonAdminMembers = members.filter((member) => member.role !== 'admin');

  const handleDeleteRow = async (userid: string) => {
    const token = localStorage.getItem('idToken');
    console.log(userid, 'id');
    if (token && id) {
      const deteled = await deletemember(token, userid, id);
      const updatedMembers = members.filter((member) => member.id !== id);
      setMembers(updatedMembers);
      setCheckedRows(checkedRows.filter((rowId) => rowId !== id));
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setCheckedRows(nonAdminMembers.map((member) => member.id));
    } else {
      setCheckedRows([]);
    }
  };

  const allNonAdminChecked =
    nonAdminMembers.length > 0 && checkedRows.length === nonAdminMembers.length;

  const removeMember = (id: string) => {
    setMembers(members.filter((member) => member.id !== id));
  };
  const generateInvitation = async (inviteDetails: InviteProp[]) => {
    console.log('id', id);
    const token = localStorage.getItem('idToken');
    if (token) {
      try {
        const inviteLinks = await Promise.all(
          inviteDetails.map(async (invite) => {
            const invitLink = await getlink(token, invite.email, id || '');
            if (invitLink.status === 402) {
              setLimitReachedModal(true);
            }
            return invitLink;
          })
        );

        console.log('Invitation Links:', inviteLinks);
      } catch (error) {
        console.error('Error generating invitation links:', error);
      }
    }
  };

  return (
    <>
      {loading ? (
        <DDSLoader loading={loading} />
      ) : (
        <div>
          <DDSModal
            className='invite-modal-padding invite-members-modal'
            defaultFooter={false}
            open={openModal}
            setOpen={setOpenModal}
            onIconClose={() => setOpenModal(false)}
            style={{
              maxHeight: '90vh',
              overflowY: 'auto',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '25px',
                  }}
                >
                  <div>
                    <DDSTypography.Title
                      type='h4'
                      className='invite-member-title'
                    >
                      Invite Member
                    </DDSTypography.Title>
                    <DDSTypography.Paragraph
                      className='invite-member-description'
                      size='caption'
                      style={{ paddingTop: '5px' }}
                    >
                      You can invite up to 4 members in one workspace.
                    </DDSTypography.Paragraph>
                  </div>

                  <MembersInvite onSendInvite={generateInvitation} />

                  <DDSTypography.Paragraph
                    size='caption'
                    className='invite-member-instructions'
                  >
                    We’ll email them instructions and a link to sign in.
                  </DDSTypography.Paragraph>

                  <InviteMemberDivider />
                </div>

                <div style={{ overflowY: 'auto', maxHeight: '40vh' }}>
                  {members.map((member) => (
                    <div
                      key={member.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px',
                        paddingBottom: '10px',
                        borderBottom: '1px solid #eaeaea',
                      }}
                    >
                      {/* <MembersInvite onSendInvite={generateInvitation} /> */}
                      <img
                        src={member.image}
                        alt={`${member.name}'s avatar`}
                        style={{
                          borderRadius: '50%',
                          width: '40px',
                          height: '40px',
                          marginRight: '15px',
                        }}
                      />
                      <div style={{ flexGrow: 1 }}>
                        <DDSTypography.Title
                          type='h5'
                          className='new-member-invite-name'
                        >
                          {member.name}
                        </DDSTypography.Title>
                        <DDSTypography.Paragraph
                          size='caption'
                          className='new-member-email'
                        >
                          {member.email}
                        </DDSTypography.Paragraph>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DDSChips
                          color='#79310E'
                          style={{
                            backgroundColor: '#FEF5E7',
                            color: '#79310E',
                            padding: '5px 10px',
                            borderRadius: '5px',
                          }}
                          className='invited-chip-text invited-member'
                          label={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '15px',
                              }}
                            >
                              <DDSTypography.Paragraph
                                size='caption'
                                className='invited-chip-text'
                              >
                                {member.status}
                              </DDSTypography.Paragraph>
                              <button
                                onClick={() => removeMember(member.id)}
                                style={{
                                  background: 'none',
                                  border: 'none',
                                  cursor: 'pointer',
                                  color: '#79310E',
                                }}
                              >
                                <InviteCrossIcon />
                              </button>
                            </div>
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <DDSTypography.Paragraph
                  size='para'
                  className='copy-link-input'
                >
                  Copy Link
                </DDSTypography.Paragraph>
                <CopyLinkIcon style={{ cursor: 'pointer' }} />
              </div>
            </div>
          </DDSModal>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isAdminWorkspace && (
              <DDSButton
                onClick={() => setOpenModal(true)}
                className='invite-member-button invite-member-button-text'
                label='Invite Member'
              />
            )}
            <div>
              <div className='members-table-container'>
                <div className='members-header'>
                  <DDSTypography.Paragraph
                    size='para'
                    className='members-count'
                  >
                    {members.length} members
                  </DDSTypography.Paragraph>
                </div>
                <table className='members-table'>
                  <thead>
                    <tr>
                      {isAdminWorkspace ? (
                        <th className='checkbox-cell'>
                          <input
                            type='checkbox'
                            style={{
                              width: '16px',
                              height: '16px',
                              color: 'red',
                            }}
                            checked={allNonAdminChecked}
                            onChange={(e: any) => {
                              handleSelectAllChange(e);
                            }}
                          />
                        </th>
                      ) : (
                        <th></th>
                      )}
                      <th>Name</th>
                      <th>Email</th>
                      <th> </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {members.map((member) => (
                      <tr key={member.id}>
                        <td className='checkbox-cell'>
                          {member.role !== 'admin' && isAdminWorkspace && (
                            <input
                              type='checkbox'
                              style={{ width: '16px', height: '16px' }}
                              checked={checkedRows.includes(member.id)}
                              onChange={() => handleCheckboxChange(member.id)}
                            />
                          )}
                        </td>
                        <td
                          className='name-cell'
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                          }}
                        >
                          <img
                            src={member.image}
                            alt=''
                            className='profile-image'
                          />
                          <DDSTypography.Paragraph
                            size='para'
                            className='member-name'
                          >
                            {member.name}
                          </DDSTypography.Paragraph>
                        </td>
                        <td>
                          <DDSTypography.Paragraph
                            size='para'
                            className='member-email'
                          >
                            {member.email}
                          </DDSTypography.Paragraph>
                        </td>
                        {member.role === 'admin' && (
                          <td>
                            <DDSTypography.Paragraph
                              size='para'
                              className='member-role'
                            >
                              {member.role}
                            </DDSTypography.Paragraph>
                          </td>
                        )}
                        <td>
                          {checkedRows.includes(member.id) && (
                            <button
                              onClick={() => handleDeleteRow(member.id)}
                              style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <BinIcon />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {limitReachedModal && (
              <DDSModal
                className='limit-reached-modal limit-exceeded-members-modal-layout new-workspace-modal-padding'
                open={limitReachedModal}
                defaultFooter={false}
                setOpen={setLimitReachedModal}
                onIconClose={() => setLimitReachedModal(false)}
                style={{
                  maxHeight: '90vh',
                  overflowY: 'auto',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                  >
                    <DDSTypography.Title
                      type='h2'
                      className='members-limit-reached-modal-title'
                    >
                      Oops! Limit Reached
                    </DDSTypography.Title>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <MembersLimitReached />
                    </div>
                    <DDSTypography.Paragraph
                      size='para'
                      className='limit-reached-description'
                    >
                      You have reached the limit of invites in your workspace.
                      To invite more members and collaborate in a workspace buy
                      another workspace
                    </DDSTypography.Paragraph>
                  </div>
                  <DDSButton
                    label='See Plans'
                    className='see-plans-button see-plans-button-text'
                  />
                </div>
              </DDSModal>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MembersTab;
