import axios from 'axios';

export const getWorkspaceDetailsById = async (workspaceId: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/workspace/`,
      {
        params: { workspace_id: workspaceId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          'ngrok-skip-browser-warning': 'true',
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching workspace name:', error);
  }
};
