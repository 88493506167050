import React, { KeyboardEvent, useState } from 'react';

import { DDSButton } from 'den-design-system';
import { checkIfUserExists } from '../services/workspace/checkUser.services';

interface MembersInputProps {
  onSendInvite: (values: MemberInviteProp[]) => void;
}

interface MemberInviteProp {
  name: string;
  email: string;
}

const MembersInvite: React.FC<MembersInputProps> = ({ onSendInvite }) => {
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState<MemberInviteProp[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const checkUserExists = async (email: string) => {
    const data = await checkIfUserExists(email);
    return data;
  };

  const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      console.log('inputValue', inputValue);

      try {
        const isUser = await checkUserExists(inputValue);
        console.log('isUser', isUser);
        if (isUser.data.length > 0) {
          setChips([
            ...chips,
            { name: isUser.data[0]['name'], email: inputValue.trim() },
          ]);
        } else {
          setChips([
            ...chips,
            { name: inputValue.trim(), email: inputValue.trim() },
          ]);
        }
        setInputValue('');
      } catch (error) {
        console.error('Error checking user:', error);
      }
      e.preventDefault();
    }
  };

  const handleChipDelete = (chipToDelete: string) => {
    setChips(chips.filter((chip) => chip.email !== chipToDelete));
  };

  const handleSendInvite = () => {
    onSendInvite(chips);
    setChips([]);
  };

  return (
    <div>
      <div
        style={{
          border: '1px solid #f0a500',
          padding: '5px',
          borderRadius: '5px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          minHeight: '40px',
          alignItems: 'center',
          transition: 'height 0.2s ease-in-out',
          justifyContent: 'flex-end',
        }}
      >
        {chips.map((chip, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#f0e5d8',
              padding: '5px 10px',
              borderRadius: '25px',
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            {chip.name} ({chip.email})
            <button
              onClick={() => handleChipDelete(chip.email)}
              style={{
                marginLeft: '5px',
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              ✕
            </button>
          </div>
        ))}
        <input
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            border: 'none',
            outline: 'none',
            backgroundColor: '#fff',
            flex: 1,
            minWidth: '150px',
          }}
          placeholder='Search email'
        />
        <DDSButton
          onClick={handleSendInvite}
          type='ghost'
          style={{
            backgroundColor: '#f0a500',
            padding: '10px',
            borderRadius: '5px',
            border: '2px solid #FFE288',
            cursor: 'pointer',
          }}
          className='send-invite-button-text'
          label='Send invite'
        />
      </div>
    </div>
  );
};

export default MembersInvite;
