import axios from 'axios';

export const getPlanTypeById = async (
  workspaceId: string
): Promise<number | null> => {
  try {
    console.log('workspaceId', workspaceId);
    // Suggestion: Refine the endpoint path to distinctly target the plan type resource. [possible bug]
    //  `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/workspace/plan-type`
    const response = await axios.get(
      `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/workspace/`,
      {
        params: {
          workspace_id: workspaceId,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          'ngrok-skip-browser-warning': 'true',
        },
      }
    );
    if (response && response.status === 200) {
      const planType = response.data[0]['planType'];
      return planType ?? null;
    } else {
      console.error('Unexpected response:', response);
      return null;
    }
  } catch (error) {
    console.error('Error fetching workspace details:', error);
    return null;
  }
};
