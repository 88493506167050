import axios from 'axios';
export const createworkspace = (
  token: string,
  workspace_name: string,
  planType: number
): Promise<{ status: number; data?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/createbusiness_workspace`;
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          workspace_name,
          planType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          resolve({
            status: response.status,
            data: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error adding members:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
