import 'den-design-system/style.css';
import '../index.css';

import {
  DDSButton,
  DDSInput,
  DDSLoader,
  DDSMenu,
  DDSMenuList,
  DDSModal,
  DDSToastNotification,
  DDSTypography,
} from 'den-design-system';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AddIcon } from '../assets/addIcon.svg';
import { ReactComponent as CommentStreamLogo } from '../assets/commentStreamLogo.svg';
import { ReactComponent as Divider } from '../assets/workspaceDropdownDivider.svg';
import { ReactComponent as DropDownIcon } from '../assets/limitExceededDropdown.svg';
import { ReactComponent as NewWorkspaceIcon } from '../assets/newWorkspaceModalIcon.svg';
import ProfileCard from './ProfileCard';
import { ReactComponent as SelectedIcon } from '../assets/selectedIcon.svg';
import { ReactComponent as SettingsLogo } from '../assets/settingsSidebarLogo.svg';
import { ReactComponent as SidebarOrganizationIcon } from '../assets/sidebarOrganization.svg';
import { ReactComponent as StreamsLogo } from '../assets/streamsSidebarLogo.svg';
import { createworkspace } from '../services/workspace/createbusiness.services';
import { filterWorkspaceOnRoles } from '../utils/filterWorkspaceOnRoles';
import { getRoleByWorkspaceId } from '../utils/getRoleByWorkspaceId';
import { getUserDetails } from '../services/userDetails/user.services';
import { getWorkspaceDetailsById } from '../utils/getWorkspaceDetailsById';
import { user_workspace } from '../services/userDetails/userWorkspace.services';
import { log } from 'console';

const getToken = () => {
  return localStorage.getItem('idToken');
};

interface ProfileProps {
  name: string;
  email: string;
  avatar?: string;
}

interface WorkspaceDetails {
  name: string;
  id: string;
  planType: number;
}

interface WorkspaceProps {
  role: string;
  workspaceData: WorkspaceDetails[];
}

interface SidebarProps {
  params: {
    id?: string;
  };
  loading:boolean;
  setLoading: (loading:boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ params,loading,setLoading }) => {
  const [profile, setProfile] = useState<ProfileProps>({
    name: '',
    email: '',
    avatar: '',
  });

  const navigate = useNavigate();
  const [myWorkspaceOpen, setMyWorkspaceOpen] = useState(true);
  const [memberWorkspaceOpen, setMemberWorkspaceOpen] = useState(true);
  const [currentWorkspaceName, setCurrentWorkspaceName] =
    useState<string>('My workspaces');
  const [selectedWorkspace, setSelectedWorkspace] = useState<string>('');
  const [organizationWorkspace, setOrganizationWorkspace] =
    useState<boolean>(true);
  const [newWorkspace, setNewWorkspace] = useState<string>('');
  const [dropdownSelected, setDropdownSelected] = useState<boolean>(false);
  const [adminWorkspaceState, setAdminWorkspaceState] = useState<
    WorkspaceDetails[]
  >([]);
  const [memberWorkspaceState, setMemberWorkspaceState] = useState<
    WorkspaceDetails[]
  >([]);
  const [roleTypeAdmin, setRoleTypeAdmin] = useState<boolean>(false);
  const [personalPlanId, setPersonalPlanId] = useState('');
  const location = useLocation();
  const disabledState = !newWorkspace
  useEffect(() => {
    const pathname = location.pathname;
    console.log('pathname', pathname);
    const fetchUserProfile = async () => {
      const token = getToken();
      console.log('params', params.id);

      setSelectedWorkspace(params.id || '');

      if (token) {
        try {
          const { status, data } = await getUserDetails(token);
          if (status === 200) {
            setProfile({
              name: data.name.split(' ')[0],
              email: data.email,
              avatar: data.avatar,
            });
            const workspaceDetails = await user_workspace(token);
            const { workspaceData } = workspaceDetails;
            console.log(workspaceData,"workspaceData")

            const workspaces: WorkspaceProps[] = workspaceData.map(
              (member: any) => ({
                role: member.role,
                workspaceData: [
                  {
                    name: member.workspace.name,
                    id: member.workspace.id,
                    planType: member.workspace.planType,
                  },
                ],
              })
            );
            const personalPlanIds = workspaces
              .filter(
                (workspace) =>
                  workspace.workspaceData[0].planType === 1 ||
                  workspace.workspaceData[0].planType === 2
              )
              .map((workspace) => workspace.workspaceData[0].id);

            setPersonalPlanId(personalPlanIds[0]);
            console.log(personalPlanIds[0],"personalPlanId")

            const hasOrganizationalWorkspace =
              workspaces.filter(
                (workspace) =>
                  workspace.workspaceData[0].planType === 3 ||
                  workspace.workspaceData[0].planType === 4
              ).length > 0;

            setOrganizationWorkspace(hasOrganizationalWorkspace);
            console.log(hasOrganizationalWorkspace,"hasOrganizationalWorkspace");
            console.log(organizationWorkspace,"organizationWorkspace");
            
            
            const { adminWorkspaces, memberWorkspaces } =
              filterWorkspaceOnRoles(workspaces);

            setAdminWorkspaceState(adminWorkspaces);
            setMemberWorkspaceState(memberWorkspaces);
          } else {
            console.error('Failed to fetch user details');
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };
    console.log('settings::', `/settings${encodeURI(params.id || '')}`);
    fetchUserProfile();
  }, [params.id]);

  useEffect(() => {
    const roleType = async () => {
      if (params.id) {
        const userData = await getRoleByWorkspaceId(params.id);
        const userRole = userData?.data.member_details[0]['role'];
        if (userRole === 'admin') {
          setRoleTypeAdmin(true);
        } else {
          setRoleTypeAdmin(false);
        }
      } else {
        setRoleTypeAdmin(false);
      }
    };

    roleType();
  }, [params.id]);

  useEffect(() => {
    const setNameDetails = async () => {
      console.log('selectedWorkspace', selectedWorkspace);
      const response = await getWorkspaceDetailsById(params.id || '');
      setCurrentWorkspaceName(response[0]['name']);
    };
    setNameDetails();
  }, []);

  const [selectedOption, setSelectedOption] =
    useState<string>('personal-workspace');
  const [menuOpen, setOpen] = useState<boolean>(false);
  const [open, setMenuOpen] = useState<boolean>(false);
  const [modalView, setModalView] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  //latest updated sidebar
  const pathname = location.pathname;
  useEffect(() => {
    if (pathname.includes('personal_settings')) {
      setDropdownSelected(false);
      setSelectedOption('personal-workspace');
    }
  }, [pathname, selectedOption]);

  useEffect(()=>{ 
      console.log("inside use effect");
      
  },[organizationWorkspace])

  console.log('selectedWorkspace', selectedWorkspace);
  //const [isLoading, setIsLoading] = useState(false);
  const handleCreateWorkspace = async () => {
    try {
      setLoading(true);
      const token = getToken();
      if (token) {
        console.log(newWorkspace, 'newWorkspace');
        const response = await createworkspace(token, newWorkspace, 3);
        
        if (response.status === 200) {
          setModalView(false);
          setShowToast(true);
          setOrganizationWorkspace(true)
          
          const workspaceDetails = await user_workspace(token);
          const { workspaceData } = workspaceDetails;
          const workspaces: WorkspaceProps[] = workspaceData.map(
            (member: any) => ({
              role: member.role,
              workspaceData: [
                {
                  name: member.workspace.name,
                  id: member.workspace.id,
                  planType: member.workspace.planType,
                },
              ],
            })
          );
          const { adminWorkspaces, memberWorkspaces } = filterWorkspaceOnRoles(workspaces);
          setAdminWorkspaceState(adminWorkspaces);
          setMemberWorkspaceState(memberWorkspaces);
          
         
        }
        console.log(response, 'response');
        if (response.status === 200) {
          setModalView(false);
          console.log('Workspace created successfully:', response.data['message']);
          const aa: string = typeof response.data === 'string' ? response.data : JSON.stringify(response.data['message']);
          console.log(aa,"aa");
          
          if (response.data['message'] === "User is already a member with role: admin"){
            DDSToastNotification.error("Workspace already exsists")
            setLoading(false)
          }
          else{
            DDSToastNotification.success("Workspace created successfully")
            setLoading(false)
          }
        } else {
          console.error('Failed to create workspace');
        }
      }
    } catch (error) {
      console.error('Error creating workspace:', error);
    }
  };
  
  const modalRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]); // Dependency array includes menuRef

  const handleWorkspaceClick = (item: WorkspaceDetails) => {
    setCurrentWorkspaceName(item.name);
    setDropdownSelected(true);
    setSelectedOption('streams');
    setOpen(false);
    setSelectedWorkspace(item.id);
    navigate(`dashboard/${encodeURI(item.id)}`);
    setDropdownSelected(false);
    
    console.log(organizationWorkspace,'organization workspace');
    
  };

  if (loading){
    return <DDSLoader loading={loading} />
  }
  return (
    
    <div className='sidebar-navbar h-screen fixed top-0 left-0 w-[250px] bg-white shadow-lg flex flex-col justify-between'>
      
      <div className='sidebar-layout flex flex-col flex-grow'>
        <div className='sidebar-logo-part flex items-center'>
          <CommentStreamLogo
            style={{
              height: '33px',
              width: '33px',
            }}
          />
          <DDSTypography.Title type='h1' className='sidebar-logo-heading'>
            Comment Stream
          </DDSTypography.Title>
        </div>
        
        <div className='personal-workspace-create-section flex-grow'>
          <div
            className={`${
              selectedOption === 'personal-workspace' &&
              'personal-workspace-region'
            }`}
          >
            <button
              onClick={() => {
                setSelectedOption('personal-workspace');
                setSelectedWorkspace(personalPlanId);
                navigate(`dashboard/${personalPlanId}`, { replace: true });
              }}
            >
              <div className='personal-workspace-inner-div'>
                <SidebarOrganizationIcon />
                <DDSTypography.Title
                  type='h2'
                  className={`personal-workspace-name`}
                >
                  {profile.name} Workspace
                </DDSTypography.Title>
              </div>
            </button>
          </div>
          <div ref={menuRef}
            className='selected-organization-menu'
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '39px',
              justifyContent: 'space-around',
            }}
            onClick={(e) => { 
              setMenuOpen(!open); 
            }}
          >
            <DDSTypography.Title
              type='h2'
              className='selected-organization-workspace'
            >
              {currentWorkspaceName}
            </DDSTypography.Title>
            <DropDownIcon
              onClick={(e) => { 
                setMenuOpen(!open);
              }}
            />
</div>



          {dropdownSelected && selectedOption !== 'personal-workspace' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <div className='streams-outer-layout'>
                <div
                  className={`${
                    selectedOption === 'streams' && 'personal-workspace-region'
                  }`}
                >
                  <div className={`streams-inner-layout`}
                  style={{
                    cursor:'pointer'
                  }}
                  onClick={() => {
                    console.log('wow', selectedWorkspace);
                    setSelectedOption('streams');
                    navigate(`dashboard/${params.id}`, {
                      replace: true,
                    });
                  }}>
                    <StreamsLogo />
                    <button
                      onClick={() => {
                        console.log('wow', selectedWorkspace);
                        setSelectedOption('streams');
                        navigate(`dashboard/${params.id}`, {
                          replace: true,
                        });
                      }}
                    >
                      <DDSTypography.Title
                        type='h3'
                        className={`sidebar-streams-title`}
                      >
                        Streams
                      </DDSTypography.Title>
                    </button>
                  </div>
                </div>
              </div>
              <div className={`streams-outer-layout`}>
                <div
                  className={`${
                    selectedOption === 'settings' && 'personal-workspace-region'
                  }`}
                >
                  <button
                    onClick={() => {
                      setSelectedOption('settings');
                      roleTypeAdmin
                        ? navigate(
                            `/my-workspace/settings/${encodeURI(
                              params.id || ''
                            )}`,
                            {
                              replace: true,
                            }
                          )
                        : navigate(
                            `/joined-workspace/settings/${encodeURI(
                              params.id || ''
                            )}`,
                            {
                              replace: true,
                            }
                          );
                    }}
                  >
                    
                    <div className='streams-inner-layout'
                     onClick={() => {
                      setSelectedOption('settings');
                      roleTypeAdmin
                        ? navigate(
                            `/my-workspace/settings/${encodeURI(
                              params.id || ''
                            )}`,
                            {
                              replace: true,
                            }
                          )
                        : navigate(
                            `/joined-workspace/settings/${encodeURI(
                              params.id || ''
                            )}`,
                            {
                              replace: true,
                            }
                          );
                    }}
                    >
                      <SettingsLogo />
                      <DDSTypography.Title
                        type='h3'
                        className='sidebar-streams-title'
                      >
                        Settings
                      </DDSTypography.Title>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
        
         
          {!organizationWorkspace && (
            <div className='create-workspace-section'>
              <img
                src='https://s3-alpha-sig.figma.com/img/1074/2aea/5c08042f06ecfa7090b49f6d5fe4050b?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aFUSdPUc24vyAh2yPAbNLMnwzL5RGZqNlGTn4cqFrtvItoUwab6wPdMZuHhT4QLoLT7QHhuIOW~8Xo~P2x21v8Mb417Gazx9sf1nzDVxKTfchOSAM6wE68SxkzDqydzw07oVmiqyDrPYfO6vdxJJFmT7uTAfxZWHbl8CYnTBqswrRwOUtyMJaQSgD6E4I~BH~cx9Tcf5c0vVbb4RZnccdPzQegv~m2yXzimGvsdV0jjqBVfFPf12QjQ6-PITvgZScdnzmR5Up8xiOgLEKwl9z1KfPYNvcY4MEbHZ8NzbMBLFv8jHV19VKr9nOrOlW4GATcXQujfZoQ7MyfW4pZCYAw__'
                alt='create workspace'
                style={{
                  width: '35px',
                  height: '35px',
                }}
              />
              <DDSTypography.Title type='h4' className='create-workspace-text'>
                Collaborate with your teammates
              </DDSTypography.Title>
              <DDSButton
                  label='Create Workspace'
                  style={{
                    width: '100%',
                  }}
                 
                  
                  onClick={(e: any) => {
                   
                          setModalView(true);
                    }
                  }
                  className='create-workspace-button create-workspace-button-text'
                />
            </div>
          )}

{modalView && (
        <DDSModal
          open={modalView}
          setOpen={setModalView}
          className='new-workspace-modal-padding modal-min-width modal-width'
          defaultFooter={false}
        >
          <div ref={modalRef}>
            <div className="flex justify-end">
              
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <NewWorkspaceIcon />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                >
                  <DDSTypography.Title
                    type='h5'
                    className='new-workspace-modal-title'
                  >
                    Workspace Name
                  </DDSTypography.Title>
                  <DDSInput
                    variant='plain'
                    type='text'
                    required={true}
                    value={newWorkspace}
                    onChange={(e) => setNewWorkspace(e.target.value)}
                    validateInput={false}
                    name=""
                  />
                </div>
                <DDSButton
                  label='Create Workspace'
                  style={{
                    width: '100%',
                  }}
                  disabled={disabledState}
                  
                  onClick={(e: any) => {
                    const strippedName =newWorkspace.replace(/\s/g, '');
                    console.log(strippedName)
                    if (strippedName.length === 0) {
                      DDSToastNotification.error('Workspace name cannot be empty or only contain spaces.');
                    }
                    else{
                          setNewWorkspace(e.target.value);
                          handleCreateWorkspace();
                          setModalView(false);
                    }
                        }}
                  className='create-workspace-button create-workspace-button-text'
                />
              </div>
            </div>
          </div>
        </DDSModal>
      )}

        </div>
        {open && (
          <div
          ref={dropdownRef}
            style={{
              position: 'absolute',
              left: '20px',
              top: '167px',
            }}
          >
            <DDSMenu
              className='dropdown-workspaces dropdown-menu'
              open={true}
              style={{
                textAlign: 'left',
                backgroundColor: '#F8F8F8',
              }}
            >
              <div 
                className='workspace-menu dropdown-workspaces'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '39px',
                }}
              >
                <DDSTypography.Title type='h2' className='my-workspaces-title'>
                  MY WORKSPACES
                </DDSTypography.Title>
                <DropDownIcon
              onClick={() => {
                
                setMyWorkspaceOpen(!myWorkspaceOpen); 
                setMenuOpen(!open); 
                setOpen(false)
              }}
                />
              </div>
              {adminWorkspaceState.length > 0 ? (
                adminWorkspaceState.map((item: WorkspaceDetails) => (
                  
                  <DDSMenu
                    open={myWorkspaceOpen}
                    key={item.id}
                    //setOpen={setMyWorkspaceOpen}
                    className='dropdown-workspaces dropdown-list'
                  >
                    
                    <DDSMenuList key={item.name} className='dropdown-list' onClick={() => {
                          handleWorkspaceClick(item)
                          console.log('wow', item.id);
                          setCurrentWorkspaceName(item.name);
                          setDropdownSelected(true);
                          setSelectedOption('streams');
                          setOpen(!menuOpen);
                          navigate(`dashboard/${encodeURI(item.id || '')}`);
                        }}
                        style={{
                          display:'flex',
                          justifyContent:'flex-start',
                          
                        }}
                        >
                      
                        {selectedWorkspace === item.id ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              gap: '15px',
                            }}
                          >
                            <SelectedIcon />
                            <DDSTypography.Paragraph
                              size='para'
                              className='workspace-name'
                              style={{
                                color: '#F89608',
                              }}
                            >
                              {item.name}
                            </DDSTypography.Paragraph>
                          </div>
                        ) : (
                          <DDSTypography.Paragraph
                            size='para'
                            className='workspace-name'
                            style={{
                              color: '#000',
                              
                              paddingLeft:'30px'
                            }}
                          >
                            {item.name}
                          </DDSTypography.Paragraph>
                        )}
                      
                    </DDSMenuList>
        
                  </DDSMenu>
                  
                ))
              ) : (
                <DDSMenuList>
                  <DDSTypography.Paragraph
                    size='para'
                    className='workspace-name'
                    style={{ color: '#79310E' }}
                  >
                    No Created Workspaces
                  </DDSTypography.Paragraph>
                </DDSMenuList>
              )}
              <Divider />
              <div
                className='workspace-menu dropdown-workspaces'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '39px',
                  paddingTop: '15px',
                  paddingBottom: '15px',
                }}
              >
                <DDSTypography.Title
                  type='h2'
                  className='joined-workspaces-title'
                >
                  JOINED WORKSPACES
                </DDSTypography.Title>
                <DropDownIcon
                  onClick={() => setMemberWorkspaceOpen(!memberWorkspaceOpen)}
                />
              </div>
              {memberWorkspaceState.length > 0 ? (
                memberWorkspaceState.map((item: any) => (
                  <DDSMenu
                    open={memberWorkspaceOpen}
                    //setOpen={setMemberWorkspaceOpen}
                  >
                    <DDSMenuList key={item.name} className='dropdown-list'>
                      <button
                        onClick={() => {
                          console.log('wow', item.id);
                          setDropdownSelected(true);
                          setOpen(!menuOpen);
                          setSelectedOption('streams');
                          setCurrentWorkspaceName(item.name);
                          navigate(`/dashboard/${encodeURI(item.id || '')}`);
                        }}
                      >
                        {selectedWorkspace === item.id ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                              gap: '15px',
                            }}
                          >
                            <SelectedIcon />
                            <DDSTypography.Paragraph
                              size='para'
                              className='workspace-name'
                              style={{
                                color: '#F89608',
                              }}
                            >
                              {item.name}
                            </DDSTypography.Paragraph>
                          </div>
                        ) : (
                          <DDSTypography.Paragraph
                            size='para'
                            className='workspace-name'
                            style={{
                              color: '#000',
                            }}
                          >
                            {item.name}
                          </DDSTypography.Paragraph>
                        )}
                      </button>
                    </DDSMenuList>
                  </DDSMenu>
                ))
              ) : (
                <DDSMenuList
                  style={{
                    paddingBottom: '15px',
                  }}
                >
                  <DDSTypography.Paragraph
                    size='para'
                    className='workspace-name'
                    style={{ color: '#79310E' }}
                  >
                    No Joined Workspaces
                  </DDSTypography.Paragraph>
                </DDSMenuList>
              )}
              <div
                style={{
                  gap: '10px',
                }}
                onClick={() => {
                  setModalView(true);
                  setOpen(!menuOpen);
                }}
                className='dropdown-create-workspace-section'
              >
                <AddIcon />
                <DDSTypography.Title
                  type='h4'
                  className='dropdown-create-workspace-title'
                >
                  Create Workspace
                </DDSTypography.Title>
              </div>
            </DDSMenu>
          </div>
        )}
        
        <div className='mt-auto'>
          <ProfileCard
            name={profile.name}
            image={profile.avatar}
            email={profile.email}
            id={personalPlanId || ''}
          />
        </div>
        
      </div>
      
    </div>
  );
};

export default Sidebar;
