import axios from 'axios';
export const getBillings = (
  token: string,
  workspace_id: string
): Promise<{ billingstatus: number; billingdata?: any }> => {
  const url = `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/billingDetail`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: { workspace_id },
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true',
        },
      })
      .then((response) => {
        console.log(response, 'general service');
        if (response.status === 200) {
          resolve({
            billingstatus: response.status,
            billingdata: response.data,
          });
        } else {
          reject({ status: response.status });
        }
      })
      .catch((error) => {
        console.error('Error fetching user details:', error);
        reject({
          status: 500,
          message: error.message || 'Unknown error occurred',
        });
      });
  });
};
