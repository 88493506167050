import 'den-design-system/style.css';
import 'firebase/compat/auth';
import '../index.css';

import {
  DDSButton,
  DDSToastNotification,
  DDSTypography,
} from 'den-design-system';
import { useEffect, useState } from 'react';

import { ReactComponent as CommentStreamLogo } from '../assets/commentStreamLogo.svg';
import { ReactComponent as LandingPageBottomLeft } from '../assets/landingPageBottomLeft.svg';
import { ReactComponent as LandingPageBottomRight } from '../assets/landingPageBottomRight.svg';
import { ReactComponent as LandingPageButton } from '../assets/landingPageButtonLogo.svg';
import { ReactComponent as LandingPageButtonGoogle } from '../assets/landingPageButtonGoogle.svg';
import { ReactComponent as LandingPageLeft } from '../assets/landingPageTopLeft.svg';
import { ReactComponent as LandingPageTopRight } from '../assets/landingPageTopRight.svg';
import { createPersonalWorkspace } from '../services/workspace/createPersonalWorkspace.services';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import { user_workspace } from '../services/userDetails/userWorkspace.services';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const provider = new firebase.auth.GoogleAuthProvider();
const LandingPage: React.FC = () => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<string>('');
  const [redirectTriggered, setRedirectTriggered] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedWorkspace && !redirectTriggered) {
      setRedirectTriggered(true);
      DDSToastNotification.warning('Successfully Logged In');
      //Commented for internal relase purpose
      // navigate(`/dashboard/${selectedWorkspace}`);
      navigate(`/dashboard/`);
    }
  }, [selectedWorkspace, redirectTriggered, isSignedIn]);

  useEffect(() => {
    const handleAuthStateChange = async (user: firebase.User | null) => {
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      if (user) {
        console.log('User is signed in: ', user);
        try {
          const token = await getNewIdToken(user.refreshToken);
          localStorage.setItem('idToken', token);
          localStorage.setItem('refreshToken', user.refreshToken);
          console.log('Token Set in localStorage', token);
          console.log('RefreshToken Set in localStorage', user.refreshToken);
        } catch (error) {
          console.error('Error getting new ID token:', error);
        }
      } else {
        console.log('No user is signed in');
      }
    };

    firebase.auth().onAuthStateChanged(handleAuthStateChange);
  }, []);
  console.log('inside landing');
  //This useEffect had some bugs before the internal launch so commented out
  // useEffect(() => {
  //   const checkExistingToken = async () => {
  //     const existingToken = localStorage.getItem('idToken');
  //     const existingUid = localStorage.getItem('uid');

  //     if (existingToken && existingUid) {
  //       try {
  //         const verifyResponse = await fetch(
  //           `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/user/verify-token`,
  //           {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({
  //               idToken: existingToken,
  //               uid: existingUid,
  //             }),
  //           }
  //         );

  //         if (verifyResponse.ok) {
  //           console.log(existingToken, existingUid, 'existingUid');
  //           const verifyData = await verifyResponse.json();
  //           if (verifyData.success) {
  //             // Token is valid, proceed with redirection
  //             console.log(existingToken, existingUid, 'verifyuser');
  //             console.log('data verifiction success');
  //             setIsSignedIn(true);
  //             navigate(`/dashboard/`);
  //             //Commented for internal relase purpose
  //             // const workspaceDetails = await user_workspace(existingToken);
  //             // const { workspaceData } = workspaceDetails;
  //             // const personalPlanIds = workspaceData
  //             //   .filter(
  //             //     (member: any) =>
  //             //       member.workspace.planType === 1 ||
  //             //       member.workspace.planType === 2
  //             //   )
  //             //   .map((member: any) => member.workspace.id);

  //             // if (personalPlanIds.length > 0) {
  //             //   setSelectedWorkspace(encodeURIComponent(personalPlanIds[0]));
  //             // }
  //           } else {
  //             // Token is invalid, clear it from localStorage
  //             localStorage.removeItem('idToken');
  //             localStorage.removeItem('uid');
  //           }
  //         }
  //       } catch (error) {
  //         console.error('Error verifying token:', error);
  //         localStorage.removeItem('idToken');
  //         localStorage.removeItem('uid');
  //       }
  //     }
  //   };

  //   checkExistingToken();
  // }, []);

  const signInWithGoogle = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    try {
      console.log('inmain func');
      localStorage.removeItem('idToken');
      localStorage.removeItem('refreshToken');
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;
      if (user) {
        const { displayName: name, email, photoURL: avatar, uid } = user;

        const idToken = await user.getIdToken(true);

        const verifyResponse = await fetch(
          `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/user/verify-token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              idToken: idToken,
              uid: uid,
            }),
          }
        );

        if (!verifyResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const verifyData = await verifyResponse.json();
        if (verifyData.success) {
          console.log('new data:', verifyData);
          const token = verifyData.idToken;
          localStorage.setItem('idToken', token);
          localStorage.setItem('uid', uid);
          console.log('Set in localStorage');

          const response = await fetch(
            `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/api/v1/user/`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': '69420',
              },
              body: JSON.stringify({
                email: email,
                name: name,
                avatar: avatar,
                id: uid,
              }),
            }
          );

          const hasuraData = await response.json();
          console.log('User added to Hasura:', hasuraData);
          if (hasuraData) {
            setIsSignedIn(true);
            navigate(`/dashboard/`);
          }
          //Commented for internal relase purpose
          // if (hasuraData) {
          //   console.log('Token::', token);
          //   const { data } = await createPersonalWorkspace(token);
          //   console.log('data', data);
          // }
          // const workspaceDetails = await user_workspace(token);
          // const { workspaceData } = workspaceDetails;
          // console.log(workspaceData, 'workspaceData');

          // const workspaces = workspaceData.map((member: any) => ({
          //   role: member.role,
          //   workspaceData: [
          //     {
          //       name: member.workspace.name,
          //       id: member.workspace.id,
          //       planType: member.workspace.planType,
          //     },
          //   ],
          // }));
          // console.log('workspaces', workspaces);

          // const personalPlanIds = workspaces
          //   .filter(
          //     (workspace: any) =>
          //       workspace.workspaceData[0].planType === 1 ||
          //       workspace.workspaceData[0].planType === 2
          //   )
          //   .map((workspace: any) => workspace.workspaceData[0].id);
          // console.log('personal plans', personalPlanIds[0]);

          // if (personalPlanIds.length > 0) {
          //   console.log('inside ;;;');

          //   setSelectedWorkspace(encodeURIComponent(personalPlanIds[0]));
          //   console.log('selected workspace', selectedWorkspace);
          // } else {
          // }
        } else {
          throw new Error('Error creating custom token: ' + verifyData.message);
        }
      }
    } catch (error: any) {
      console.error('Not able to sign in:', error);
    }
  };

  const getNewIdToken: any = async (refreshToken: string) => {
    const url = `https://securetoken.googleapis.com/v1/token?key=${config.apiKey}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log('New Token:', data);
      const newIdToken = data.id_token;
      return newIdToken;
    } else {
      console.error('Error refreshing token:', data.error);
      throw new Error(data.error.message);
    }
  };
  console.log('selected workspace', selectedWorkspace);

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          height: '130px',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <LandingPageLeft style={{ marginRight: '10px' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '5px',
              marginTop: '43px',
            }}
          >
            <CommentStreamLogo
              style={{
                height: '33px',
                width: '33px',
              }}
            />
            <DDSTypography.Title type='h3' className='landing-page-title'>
              Comment Stream
            </DDSTypography.Title>
          </div>
        </div>
        {/* //Commented for internal launch purposes */}
        {/* <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginTop: '43px',
              gap: '25px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <DDSTypography.Paragraph
                color='#66442c'
                size='caption'
                link={true}
                href='/'
                className='landing-page-options'
              >
                Use Cases
              </DDSTypography.Paragraph>
              <DDSTypography.Paragraph
                color='#66442c'
                link={true}
                href='/'
                size='caption'
                className='landing-page-options'
              >
                Pricing
              </DDSTypography.Paragraph>
              <DDSTypography.Paragraph
                color='#66442c'
                size='caption'
                link={true}
                href='/'
                className='landing-page-options'
              >
                How to use
              </DDSTypography.Paragraph>
            </div>
            <DDSButton
              label='Download Extension'
              shape='rounded'
              style={{
                backgroundColor: '#ffe288 !important',
                borderRadius: '112.5px',
                border: `1.731px solid rgba(0, 0, 0, 0.25)`,
                marginRight: '10px',
              }}
              className='landing-page-button-extension landing-page-button-extension-text button-prefix-icon'
              startIcon={<LandingPageButton />}
            />
          </div>
          <LandingPageTopRight />
        </div> */}
      </div>
      <div
        className='landing-page'
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <img
            style={{
              width: '790px',
              maxWidth: '900px',
              height: '490px',
              marginRight: '60px',
              marginTop: '30px',
            }}
            src={require('../assets/CS-Illustration-MVP Page_1 (1).gif')}
            alt='Landing Page Gif'
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '45px',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '25px',
              flexDirection: 'column',
            }}
            className='landing-page-desc'
          >
            <div
              style={{
                width: '424px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <DDSTypography.Title
                type='h1'
                className='landing-page-paragraph'
                color='#66442C'
              >
                Comment Stream
              </DDSTypography.Title>
              <DDSTypography.Paragraph
                color='#66442c'
                size='para'
                className='landing-page-description'
              >
                Comment Stream is a web extension designed for Google Drive
                recordings, enabling seamless collaboration through comments and
                reactions. Sign in via Google to interact with shared videos
                within your organization.
              </DDSTypography.Paragraph>
            </div>
            <DDSButton
              startIcon={<LandingPageButtonGoogle />}
              onClick={signInWithGoogle}
              label='Continue with Google'
              className='landing-page-sign-in-button landing-page-sign-in-button-text'
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
          bottom: 0,
        }}
      >
        <LandingPageBottomLeft />
        <div
          style={{
            display: 'flex',
          }}
        >
          <LandingPageBottomRight />
        </div>
        {/* {signIn ? <SignIn /> : null} */}
      </div>
    </div>
  );
};

export default LandingPage;
