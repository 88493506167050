import { DDSLoader, DDSToast, DDSToastNotification } from 'den-design-system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verify } from '../services/invitation/verify.services';
import { addMembers } from '../services/workspace/addMember.services';
import { checkIfUserExists } from '../services/workspace/checkUser.services';

const LoginSuccess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const workspace_id = params.get('workspace_id');
  const invitation_code = params.get('invitation_code');
  const email = params.get('email');


  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!workspace_id || !invitation_code) {
      DDSToastNotification.error("Missing details");
    } else {
      handleRedirect();
    }
  }, [workspace_id, invitation_code]);

  const handleRedirect = async () => {
    console.log("injjni");
    
    try {
      console.log("inside try");
      
      setIsLoading(true);
      const token = localStorage.getItem('idToken');
      console.log('token',token);
      
      if(token){
        const verifying = await verify(token,invitation_code||"")
        console.log(verifying,"verifying")
        console.log(email,"email")
        const isUser = await checkIfUserExists(email||"");
        
        console.log('isUser', isUser);
        if(!isUser.exists){
        const sign = await addMembers(token,workspace_id || '','member');
        console.log(sign,"sign")
        }
        else{
          if(invitation_code && workspace_id){
            console.log(`/home/${encodeURI(invitation_code)}/${encodeURI(workspace_id)}`);
            navigate(`/home/${encodeURI(invitation_code)}/${encodeURI(workspace_id)}`)

          }
          console.log("no token");
          
        }
        
      }
      else{
        if(invitation_code && workspace_id){
          console.log(`/home/${encodeURI(invitation_code)}/${encodeURI(workspace_id)}`);
          navigate(`/home/${encodeURI(invitation_code)}/${encodeURI(workspace_id)}`)

        }
        console.log("no token");
        
      }
      
    } catch (e) {
      console.log("onside catch",e);
      
      DDSToastNotification.error("error in adding to workspace");
    } finally {
      console.log("inside finally");
      
      setIsLoading(false);
    }
  };
  return (
  <><DDSLoader label={'Signing you in...'} loading={isLoading} />
  <DDSToast position='bottom-right'/>
  </>)
  ;

}
export default LoginSuccess;



