import { DDSButton, DDSLoader, DDSTypography } from 'den-design-system';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AssistIcon } from '../assets/assist.svg';
import { ReactComponent as CommentIcon } from '../assets/commentIcon.svg';
import { ReactComponent as EmptyImage } from '../assets/emptyImage.svg';
import { ReactComponent as NotificationIconBottom } from '../assets/notificationBottom.svg';
import { ReactComponent as NotificationIconTop } from '../assets/notificationTop.svg';
import { getPlanTypeById } from '../utils/getPlanTypeById';
import { getUserDetails } from '../services/userDetails/user.services';
import { getWorkspaceVideo } from '../services/workspace/settings.services';
import { useParams } from 'react-router-dom';
import { user_workspace } from '../services/userDetails/userWorkspace.services';
const getToken = () => {
  return localStorage.getItem('idToken');
};
interface Video {
  id: string;
  video_url: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  commentsCount: number;
}
interface VideoGroup {
  video_id: string;
  group_id: string;
  video: Video;
}
const WorkSpace = () => {
  const [name, setName] = useState('');
  const [planType, setPlanType] = useState<number | null>(1);
  const [loading, setIsLoading] = useState(true);
  const [workspaceId, setWorkspaceId] = useState('');
  const [videos, setVideos] = useState<VideoGroup[]>([]);
  const { id } = useParams<{
    id: string;
  }>();
  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      const token = getToken();
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        // Fetch user details
        const { data: userData } = await getUserDetails(token);
        setName(userData.name.split(' ')[0]);
        // Fetch workspace and video details
        let currentWorkspaceId = id;
        if (!currentWorkspaceId) {
          const workspaceDetails = await user_workspace(token);
          const { workspaceData } = workspaceDetails;
          const workspaces = workspaceData.map((member: any) => ({
            role: member.role,
            workspaceData: [
              {
                name: member.workspace.name,
                id: member.workspace.id,
                planType: member.workspace.planType,
              },
            ],
          }));
          const personalPlanIds = workspaces
            .filter((workspace: any) =>
              [1, 2].includes(workspace.workspaceData[0].planType)
            )
            .map((workspace: any) => workspace.workspaceData[0].id);
          currentWorkspaceId = personalPlanIds[0];
        }
        setWorkspaceId(currentWorkspaceId || '');
        // Fetch video data
        const videoData = await getWorkspaceVideo(
          token,
          currentWorkspaceId || ''
        );
        if (videoData && videoData.data.videos.length > 0) {
          setVideos(
            videoData.data.videos.map((videoGroup: any) => ({
              video_id: videoGroup[0].video_id,
              group_id: videoGroup[0].group_id,
              video: {
                id: videoGroup[0].video.id,
                video_url: videoGroup[0].video.video_url,
                thumbnail: videoGroup[0].video.thumbnail || '',
                title: videoGroup[0].video.title || '',
                updatedAt: videoGroup[1],
                commentsCount: videoGroup[2],
              },
            }))
          );
        }
        if (id) {
          const planTypeData = await getPlanTypeById(id);
          console.log('planTypeData', planTypeData);
          setPlanType(planTypeData);
          console.log('planType', planType);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllData();
  }, [id]);
  const handleVideoClick = (videoUrl: string) => {
    window.open(videoUrl, '_blank');
  };
  console.log('videos', videos, planType);
  return (
    <>
      {loading ? (
        <DDSLoader loading />
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '10px',
            position: 'relative',
          }}
        >
          <div className='workspace-heading'>
            <DDSTypography.Title
              color='black'
              type='h3'
              className='workspace-title'
            >
              {name} Workspace
            </DDSTypography.Title>
            <div className='workspace-heading-notification-icon-section'>
              {planType === 1 && (
                <DDSButton
                  label='Upgrade to Pro'
                  className='workspace-upgrade-button'
                />
              )}
              <div className='workspace-notification'>
                <NotificationIconTop />
                <NotificationIconBottom />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '94vh',
              width: '100%',
            }}
          >
            {videos.length === 0 ? (
              <>
                <EmptyImage style={{ width: '180px', height: '180px' }} />
                <DDSTypography.Paragraph
                  color='#A26547'
                  size='caption'
                  className='empty-icon-description'
                >
                  Start using comment stream to view your streams here
                </DDSTypography.Paragraph>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  height: '94vh',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    height: '150px',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  {videos.map((videoGroup, index) => (
                    <div
                      key={videoGroup.video_id}
                      className='video-card'
                      onClick={() =>
                        handleVideoClick(videoGroup.video.video_url)
                      }
                    >
                      <div
                        className='video-thumbnail'
                        style={{
                          justifyContent: 'space-around',
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '75px',
                          backgroundImage: `url(${videoGroup.video.thumbnail})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      >
                        <div className='play-icon'>AA</div>
                      </div>
                      <div className='video-info'>
                        <DDSTypography.Paragraph
                          size='caption'
                          className='video-updated-text'
                        >
                          Updated {videoGroup.video.updatedAt}
                        </DDSTypography.Paragraph>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                          }}
                        >
                          <CommentIcon
                            style={{ width: '18px', height: '18px' }}
                          />
                          <DDSTypography.Paragraph
                            size='para'
                            className='video-total-comments'
                          >
                            {videoGroup.video.commentsCount}
                          </DDSTypography.Paragraph>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className='assist-icon-section'>
            <AssistIcon />
          </div>
        </div>
      )}
    </>
  );
};
export default WorkSpace;