type Workspace = {
  name: string;
  id: string;
  planType: number;
};

type MemberDetail = {
  role: string;
  workspaceData: Workspace[];
};

type MemberDetailsResponse = MemberDetail[];

// Define the state types
type AdminWorkspaceState = Workspace[];
type MemberWorkspaceState = Workspace[];

export const filterWorkspaceOnRoles = (
  data: MemberDetailsResponse
): {
  adminWorkspaces: AdminWorkspaceState;
  memberWorkspaces: MemberWorkspaceState;
} => {
  const adminWorkspaces: AdminWorkspaceState = [];
  const memberWorkspaces: MemberWorkspaceState = [];

  // Iterate over each member detail
  data.forEach((memberDetail) => {
    const { role, workspaceData } = memberDetail;

    // For each workspaceData entry
    workspaceData.forEach((workspace) => {
      if (
        role === 'admin' &&
        workspace.planType !== 1 &&
        workspace.planType !== 2 &&
        (workspace.planType === 3 || workspace.planType === 4)
      ) {
        adminWorkspaces.push(workspace);
      } else if (role === 'member') {
        memberWorkspaces.push(workspace);
      }
    });
  });

  return { adminWorkspaces, memberWorkspaces };
};
