import 'den-design-system/style.css';
import '../index.css';

import { DDSButton, DDSTypography } from 'den-design-system';

import BillingHistory from './BillingHistory';
import { ReactComponent as BulletIcon } from '../assets/bulletTick.svg';
import React, { useEffect, useState } from 'react';
import { user_workspace } from '../services/userDetails/userWorkspace.services';
import { Plans } from '../services/personalSettingDetails/personalplans.services';
import { useParams } from 'react-router-dom';
import { getPlanTypeById } from '../utils/getPlanTypeById';

const PersonalPlans = () => {
  interface PlanDetails {
    id: number;
    name: string;
    memberLimit: number;
    videoLimit: number;
    rate: string;
    description: string;
    timeLimit: string;
  }

  const [freeplan, setFreePlan] = useState<PlanDetails>({
    id: 0,
    name: "",
    memberLimit: 0,
    videoLimit: 0,
    rate: "",
    description: "",
    timeLimit: "",
  });

  const [pro, setProPlan] = useState<PlanDetails>({
    id: 0,
    name: "",
    memberLimit: 0,
    videoLimit: 0,
    rate: "",
    description: "",
    timeLimit: "",
  });

  const [planType, setPlanType] = useState(1);
  const { id } = useParams<{ id: string }>();
  

  useEffect(() => {
    const token = localStorage.getItem('idToken');

    const handleFetchData = async () => {
      if (token) {
        try {
          let plandata = await Plans(token, 1);
          console.log(plandata.plandata[0], "plandata");
          setFreePlan({
            id: plandata.plandata[0]['id'],
            name: plandata.plandata[0]['name'],
            memberLimit: plandata.plandata[0]['memberlimit'],
            videoLimit: plandata.plandata[0]['videolimit'],
            rate: plandata.plandata[0]['rate'],
            description: plandata.plandata[0]['details'],
            timeLimit: plandata.plandata[0]['timelimit'],
          });

          plandata = await Plans(token, 2);
          console.log(plandata.plandata[0], "plandata");
          setProPlan({
            id: plandata.plandata[0]['id'],
            name: plandata.plandata[0]['name'],
            memberLimit: plandata.plandata[0]['memberlimit'],
            videoLimit: plandata.plandata[0]['videolimit'],
            rate: plandata.plandata[0]['rate'],
            description: plandata.plandata[0]['details'],
            timeLimit: plandata.plandata[0]['timelimit'],
          });

          const result1 = await user_workspace(token);
          const { workspaceStatus, workspaceData } = result1;

          if (workspaceStatus === 200) {          
            if (id) {
              const planType = await getPlanTypeById(id);
              console.log('planType', planType);
              setPlanType(planType||1);
            }
             
          }

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    handleFetchData();
  }, []);

  const getPlanStyle = (type: number | null) => {
    if (type === 1) {
      return { }; 
    } else if (type === 2) {
      return {  }; 
    }
    return { backgroundColor: '#FFF1C6' };; 
  };
  console.log("plantype",planType);
  
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
        }}
      >
        <div
          style={{
            border: '1px solid #F89608',
            borderRadius: '20px',
            height: '406px',
            padding: '20px 20px 105px 20px',
            width: '250px',
            textAlign: 'left',
            ...getPlanStyle(planType === 1 ? 1 : null) 
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '7px',
            }}
          >
            <DDSTypography.Title
              type='h4'
              color='#79310E'
              className='plan-heading'
            >
              {freeplan.name}
            </DDSTypography.Title>

            <DDSTypography.Paragraph size='para' className='plan-description'>
              Get started with personal workspace
            </DDSTypography.Paragraph>
          </div>
          <DDSTypography.Title
            type='h2'
            color='black'
            className='plan-cost'
            style={{
              padding: '50px 0px 50px 0px',
            }}
          >
            {freeplan.rate}
          </DDSTypography.Title>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <DDSTypography.Paragraph
              size='para'
              className='plan-features-title'
            >
              Key features
            </DDSTypography.Paragraph>

            <div className='plan-features-list'>
              <div className='plan-features-list-option'>
                <BulletIcon />
                <DDSTypography.Paragraph size='caption'>
                  a personal workspace
                </DDSTypography.Paragraph>
              </div>
              <div className='plan-features-list-option'>
                <BulletIcon />
                <DDSTypography.Paragraph size='caption'>
                  Add comments up to {freeplan.videoLimit} streams
                </DDSTypography.Paragraph>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            border: '1px solid #F89608',
            borderRadius: '20px',
            padding: '20px',
            width: '250px',
            height: '406px',
            textAlign: 'left',
            ...getPlanStyle(planType === 2 ? 2 : null) 
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '7px',
            }}
          >
            <DDSTypography.Title
              type='h4'
              color='#79310E'
              className='plan-heading'
            >
              {pro.name}
            </DDSTypography.Title>

            <DDSTypography.Paragraph size='para' className='plan-description'>
              Get started with personal workspace
            </DDSTypography.Paragraph>
          </div>
          <DDSTypography.Title
            type='h2'
            color='black'
            className='plan-cost'
            style={{
              padding: '50px 0px 50px 0px',
            }}
          >
            {pro.rate}
          </DDSTypography.Title>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <DDSTypography.Paragraph
              size='para'
              className='plan-features-title'
            >
              Key features
            </DDSTypography.Paragraph>

            <div className='plan-features-list'>
              <div className='plan-features-list-option'>
                <BulletIcon />
                <DDSTypography.Paragraph size='caption'>
                  a personal workspace
                </DDSTypography.Paragraph>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '25px',
                }}
              >
                <div className='plan-features-list-option'>
                  <BulletIcon />
                  <DDSTypography.Paragraph size='caption'>
                    Add comments up to unlimited streams
                  </DDSTypography.Paragraph>
                </div>
                <div>
                 
                </div>
                {planType == 1 && (
                  <DDSButton
                    label='Upgrade to Pro'
                    className='upgrade-button upgrade-button-text'
                  />
                )}
                
         
              </div>
            </div>
          </div>
        </div>
      </div>
      {planType != 1 && <BillingHistory />}
    </div>
  );
};

export default PersonalPlans;
