import 'den-design-system/style.css';
import '../index.css';

import {
  DDSButton,
  DDSCheckbox,
  DDSInput,
  DDSLoader,
  DDSModal,
  DDSRadioGroup,
  DDSTabs,
  DDSTypography,
} from 'den-design-system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as AssistIcon } from '../assets/assist.svg';
import MembersTab from './MembersTab';
import PlanAndBilling from './PlanAndBillingPersonal';
import Plans from './Plans';
import { ReactComponent as WorkspaceNotificationBottomIcon } from '../assets/workspaceNotificationBottomIcon.svg';
import { ReactComponent as WorkspaceNotificationTopIcon } from '../assets/workspaceNotificationTop.svg';
import { deteleworkspace } from '../services/workspace/delete.services';
import { getPersonalWorkspaceId } from '../services/userDetails/personalworkspace';
import { getPlanTypeById } from '../utils/getPlanTypeById';
import { getSetting } from '../services/personalSettingDetails/general.services';
import { updateNotificationPreference } from '../services/personalSettingDetails/notification.services';
import { updateWorkspaceName } from '../services/personalSettingDetails/workspacename.services';
import { user_workspace } from '../services/userDetails/userWorkspace.services';

const options = [
  {
    value: 'false',
    label: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
        }}
      >
        <DDSTypography.Paragraph
          size='caption'
          className='settings-general-email-notifications-option'
        >
          Off
        </DDSTypography.Paragraph>
        <DDSTypography.Paragraph
          size='caption'
          className='settings-general-email-notifications-option-description'
        >
          You won’t receive any notifications
        </DDSTypography.Paragraph>
      </div>
    ),
    disabled: false,
  },
  {
    value: 'true',
    label: (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DDSTypography.Paragraph
          size='caption'
          className='settings-general-email-notifications-option'
        >
          On
        </DDSTypography.Paragraph>
        <DDSTypography.Paragraph
          size='caption'
          className='settings-general-email-notifications-option-description'
        >
          You'll get email notifications when someone replies to you
        </DDSTypography.Paragraph>
      </div>
    ),
    disabled: false,
  },
];

interface WorkspaceProps {
  isPersonal: boolean;
}

interface SettingProp {
  name: string;
  email: string;
  notificationPreference: string;
}

const Settings: React.FC<WorkspaceProps> = ({ isPersonal }) => {
  const { id } = useParams<{ id: string }>();
  const [details, setDetails] = useState<SettingProp>({
    name: '',
    email: '',
    notificationPreference: '',
  });
  const [buttonDisabled, setDeleteButtonDisabled] = React.useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [workspaceId, setWorkspaceId] = React.useState<string>('');
  const [planType, setPlanType] = React.useState<number | null>(0);
  const [deleteWorkspace, setDeleteWorkspace] = React.useState<boolean>(false);
  const [adminWorkspace, setAdminWorkspace] = React.useState<boolean>(true);
  const navigate = useNavigate();
  console.log('inside settings ', id);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before starting data fetching
      try {
        const token = localStorage.getItem('idToken');
        if (token) {
          // Handle location.pathname logic
          const pathname = location.pathname;
          if (pathname.includes('my-workspace')) {
            setAdminWorkspace(true);
          } else {
            setAdminWorkspace(false);
          }
          // Handle fetching plan details if id is available
          if (id) {
            setWorkspaceId(id);
            const planType = await getPlanTypeById(id);
            console.log('planType', planType);
            setPlanType(planType);
          }
          // Handle fetching workspace details and settings
          const result1 = await user_workspace(token);
          const { workspaceStatus, workspaceData } = result1;
          if (workspaceStatus === 200) {
            const filteredWorkspaces = workspaceData.filter(
              (workspace: any) =>
                workspace.workspace['planType'] === 1 ||
                workspace.workspace['planType'] === 2
            );
            if (filteredWorkspaces.length > 0) {
              //const workspace_id = filteredWorkspaces[0].workspace['id'];
              const settingsWorkspace = await getSetting(token, id || '');
              const newNotificationPreference = String(
                settingsWorkspace.data[0]['notificationPreference']
              );
              setDetails({
                name: settingsWorkspace.data[0].workspace_members[0].workspace[
                  'name'
                ],
                email: settingsWorkspace.data[0]['email'],
                notificationPreference: newNotificationPreference,
              });
              console.log('Updated Details:', {
                name: settingsWorkspace.data[0].workspace_members[0].workspace[
                  'name'
                ],
                email: settingsWorkspace.data[0]['email'],
                notificationPreference: newNotificationPreference,
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [location.pathname, id]);

  const handleDeleteWorkspace = async () => {
    const token = localStorage.getItem('idToken');
    if (token && id) {
      const { personalWorkspace } = await getPersonalWorkspaceId(token);
      const workspace = await deteleworkspace(token, id);
      // Suggestion: Adjust the navigation path to a relative client-side route instead of using the backend URL. [possible issue]
      // navigate(`/dashboard/${personalWorkspace}`);
      if (workspace.status === 200) {
        navigate(
          `${process.env.REACT_APP_COMMENT_STREAM_BACKEND_URL}/dashboard/${personalWorkspace}`
        );
      }
    }
  };
  const handleNotificationChange = async (newPreference: string) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      notificationPreference: newPreference,
    }));
    const token = localStorage.getItem('idToken');
    if (token) {
      try {
        await updateNotificationPreference(token, newPreference === 'true');
        console.log(
          'Notification preference updated to:',
          newPreference === 'true'
        );
      } catch (error) {
        console.error('Error updating notification preference:', error);
      }
    }
  };

  const handleWorkspaceNameSave = async () => {
    const token = localStorage.getItem('idToken');
    if (token) {
      try {
        await updateWorkspaceName(token, id || '', details.name);
        console.log(id, '{ name updated to:', details.name);
      } catch (error) {
        console.error('Error updating workspace name:', error);
      }
    }
  };

  const personalTabOptions = [
    {
      label: 'General',
      children: (
        <div className='settings-general-workspace-section'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <DDSTypography.Paragraph
              size='caption'
              className='settings-general-workspace-heading'
            >
              My workspace
            </DDSTypography.Paragraph>
            <DDSInput
              name='workspace-name'
              value={details.name}
              validateInput={true}
              variant='plain'
              onChange={(e) => {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  name: e.target.value,
                }));
              }}
              style={{
                width: '40%',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                justifyContent: 'flex-end',
                width: '40%',
              }}
            >
              <DDSButton
                label='Cancel'
                type='outline'
                style={{
                  width: '71px !important',
                }}
                className='settings-general-cancel-button settings-general-cancel-button-text'
              />
              <DDSButton
                label='Save'
                className='settings-general-save-button settings-general-save-button'
                onClick={handleWorkspaceNameSave}
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              flexDirection: 'column',
              width: '40%',
            }}
          >
            <DDSTypography.Paragraph
              size='caption'
              className='settings-general-email-section'
            >
              Email
            </DDSTypography.Paragraph>
            <DDSInput
              name='workspace-email'
              disabled={true}
              variant='plain'
              value={details.email}
              className='settings-general-email-input'
              validateInput={false}
              onChange={() => {}}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <DDSTypography.Paragraph
              size='caption'
              className='settings-general-email-notifications'
            >
              Email Notifications
            </DDSTypography.Paragraph>
            <DDSRadioGroup
              direction='vertical'
              options={options}
              value={details.notificationPreference}
              onChange={(e) => handleNotificationChange(e.target.value)}
            />
          </div>
        </div>
      ),
    },
    {
      label: 'Plans and Billings',
      children: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PlanAndBilling />
          <div
            style={{
              paddingTop: '60px',
            }}
          >
            <Plans isOrganization={false} />
          </div>
        </div>
      ),
    },
  ];
  const memberWorkspaceOptions = [
    {
      label: 'General',
      children: (
        <div className='settings-general-workspace-section'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '60%',
            }}
          >
            <DDSTypography.Paragraph
              size='caption'
              className='settings-general-workspace-heading'
            >
              Workspace name
            </DDSTypography.Paragraph>
            <DDSInput
              name='workspace-name'
              disabled={true}
              className='input-member-disabled '
              value={details.name}
              validateInput={true}
              variant='plain'
              onChange={() => {}}
              style={{
                width: '60%',
              }}
            />
          </div>
        </div>
      ),
    },
    {
      label: 'Members',
      children: <MembersTab isAdminWorkspace={false} />,
    },
    {
      label: 'Plans and Billings',
      children: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PlanAndBilling />
        </div>
      ),
    },
  ];

  const organizationTabOptions = [
    {
      label: 'General',
      children: (
        <div className='settings-general-workspace-section'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <DDSTypography.Paragraph
              size='caption'
              className='settings-general-workspace-heading'
            >
              My workspace
            </DDSTypography.Paragraph>
            <DDSInput
              name='workspace-name'
              value={details.name}
              validateInput={true}
              variant='plain'
              onChange={(e) => {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  name: e.target.value,
                }));
              }}
              style={{
                width: '40%',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                justifyContent: 'flex-end',
                width: '40%',
              }}
            >
              <DDSButton
                label='Cancel'
                type='outline'
                style={{
                  width: '71px !important',
                }}
                className='settings-general-cancel-button settings-general-cancel-button-text'
              />
              <DDSButton
                label='Save'
                className='settings-general-save-button settings-general-save-button'
                onClick={handleWorkspaceNameSave}
              />
            </div>
          </div>
          <DDSTypography.Title type='h4' className='delete-workspace-title'>
            Delete this workspace
          </DDSTypography.Title>
          <DDSButton
            label='Delete'
            onClick={() => {
              setDeleteWorkspace(true);
            }}
            className='delete-workspace-button delete-workspace-button-text'
          />
          <DDSModal
            defaultFooter={false}
            open={deleteWorkspace}
            setOpen={setDeleteWorkspace}
            onIconClose={() => setDeleteWorkspace(false)}
            className='delete-workspace-modal-padding modal-min-width modal-width'
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <DDSTypography.Title
                type='h3'
                className='delete-workspace-modal-title'
              >
                Delete Your workspace
              </DDSTypography.Title>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <DDSTypography.Paragraph
                  size='caption'
                  className='delete-workspace-modal-description'
                >
                  This will permanently delete your workspace with all the
                  streams and members associated with it. This action cannot be
                  undone.
                </DDSTypography.Paragraph>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    alignItems: 'center',
                  }}
                >
                  <DDSCheckbox
                    className='delete-checkbox'
                    style={{
                      cursor: 'pointer',
                    }}
                    onChange={() => {
                      setDeleteButtonDisabled(false);
                    }}
                  />
                  <DDSTypography.Paragraph
                    size='caption'
                    className='delete-workspace-modal-description'
                  >
                    Yes, I’m aware of this action.
                  </DDSTypography.Paragraph>
                </div>
                <DDSButton
                  disabled={buttonDisabled}
                  onClick={handleDeleteWorkspace}
                  label='Delete Workspace'
                  className='delete-workspace-modal-button-text delete-workspace-modal-button'
                />
              </div>
            </div>
          </DDSModal>
        </div>
      ),
    },
    {
      label: 'Members',
      children: <MembersTab isAdminWorkspace={true} />,
    },
    {
      label: 'Plans and Billings',
      children: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',

            //paddingLeft:"250px"
          }}
        >
          <PlanAndBilling />
          <div
            style={{
              paddingTop: '60px',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <Plans isOrganization={true} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        height: '100%',
        justifyContent: 'center',
        paddingLeft: '250px',
      }}
    >
      {loading ? (
        <DDSLoader loading={loading} />
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            paddingLeft: '50px',
            gap: '10px',
          }}
        >
          <div className='workspace-heading'>
            <DDSTypography.Title
              color='black'
              type='h3'
              className='workspace-title'
            >
              Settings
            </DDSTypography.Title>
            <div className='workspace-heading-notification-icon-section'>
              {planType === 1 && (
                <DDSButton
                  label='Upgrade to Pro'
                  className='workspace-upgrade-button'
                />
              )}
              <div className='workspace-notification'>
                <WorkspaceNotificationTopIcon />
                <WorkspaceNotificationBottomIcon />
              </div>
            </div>
          </div>
          <div>
            {isPersonal ? (
              <DDSTabs
                className='tab-selected'
                tabOptions={personalTabOptions}
              />
            ) : adminWorkspace ? (
              <DDSTabs
                className='tab-selected'
                tabOptions={organizationTabOptions}
              />
            ) : (
              <DDSTabs
                className='tab-selected'
                tabOptions={memberWorkspaceOptions}
              />
            )}
          </div>
          <div className='assist-icon-section'>
            <AssistIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
